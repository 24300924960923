// @flow

import { useCallback } from 'react';
import { useMatch, useParams, generatePath } from 'react-router-dom';
import { useViewerId } from 'hooks/useViewerId';

import { PATH } from 'config/constants';
import { useReporterSubPath } from './useReporterSubPath';
import { pick } from 'ramda';
import { getPriorsQueryParametersSuffix } from './usePriorStudiesParams';

type UrlBuilderArgs = {
  isViewer?: ?boolean,
  isReporter?: ?boolean,
  isURT?: ?boolean,
};

export const useUrlBuilder = (
  {
    isViewer: forceViewer = null,
    isReporter: forceReporter = null,
    isURT: forceURT = null,
  }: UrlBuilderArgs = { ...null }
): (({ caseSmid?: ?string, studySmids?: Array<string>, viewerId?: string }) => ?string) => {
  const params = useParams();
  const matchViewer = useMatch(PATH.VIEWER) != null;
  const matchReporter = useMatch(PATH.REPORTER) != null;
  const matchURT = useMatch(PATH.URT) != null;

  const isViewer =
    forceViewer != null || forceReporter != null || forceURT != null ? forceViewer : matchViewer;
  const isReporter =
    forceViewer != null || forceReporter != null || forceURT != null
      ? forceReporter
      : matchReporter;
  const isURT =
    forceViewer != null || forceReporter != null || forceURT != null ? forceURT : matchURT;

  const reporterSubPath = useReporterSubPath();

  const viewerIdFromUrl = useViewerId();

  const buildUrl = useCallback(
    ({ caseSmid, studySmids, viewerId = viewerIdFromUrl }) => {
      if (caseSmid == null) {
        return null;
      }
      const priorStudies = (studySmids ?? []).join(',');

      const priorsSuffix = getPriorsQueryParametersSuffix(priorStudies);

      if (isViewer === true) {
        return `${generatePath(PATH.VIEWER, {
          worklistId: caseSmid,
          windowId: viewerId ?? '0',
        })}${priorsSuffix}`;
      } else if (reporterSubPath != null) {
        return `${generatePath(reporterSubPath, {
          ...pick(['templateId', 'macroId'], params),
          worklistId: caseSmid,
        })}${priorsSuffix}`;
      } else if (isReporter === true) {
        return `${generatePath(PATH.REPORTER, { worklistId: caseSmid })}${priorsSuffix}`;
      } else if (isURT === true) {
        return `${generatePath(PATH.URT, { worklistId: caseSmid })}${priorsSuffix}`;
      }
    },
    [isReporter, isViewer, isURT, params, reporterSubPath, viewerIdFromUrl]
  );

  return buildUrl;
};
