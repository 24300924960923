// @flow

const spacings = {
  none: 0,
  xxsmall: 0.2,
  xsmall: 0.6,
  small: 1.2,
  medium: 1.8,
  large: 2.4,
  xlarge: 3,
  xxlarge: 3.6,
  xxxlarge: 4.8,
  xxxxlarge: 6,
};

export default spacings;
