// @flow
import { css } from 'styled-components';

export type TextVariant =
  | 'small'
  | 'caption'
  | 'body1'
  | 'body2'
  | 'button'
  | 'display1'
  | 'display2'
  | 'display3'
  | 'display4';

const TextCss: {
  [TextVariant]: $FlowFixMe,
} = {
  small: css({
    fontSize: '1.1rem',
    lineHeight: '1.5rem',
  }),
  caption: css({
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
  }),
  body1: css({
    fontSize: '1.4rem',
    lineHeight: '2.2rem',
  }),
  body2: css({
    fontSize: '1.3rem',
    lineHeight: '2.2rem',
  }),
  button: css({
    fontSize: '1.4rem',
    lineHeight: '2.2rem',
    fontWeight: '500',
    letterSpacing: '0.025rem',
  }),
  display1: css({
    fontSize: '1.6rem',
    fontWeight: '500',
    lineHeight: '2.4rem',
  }),
  display2: css({
    fontSize: '2.2rem',
    fontWeight: '500',
    lineHeight: '2.6rem',
  }),
  display3: css({
    fontSize: '3.6rem',
    lineHeight: '4.4rem',
  }),
  display4: css({
    fontSize: '4.8rem',
    fontWeight: '500',
    lineHeight: '6.4rem',
  }),
};

export default TextCss;
