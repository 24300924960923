// @flow
/**
 * Per FDA guidelines, we are required to display a disclaimer on "mobile devices"
 * Detecting all the mobile user agents is error prone, so we attempt to detect the
 * desktop operative systems instead.
 */

export const isDesktop = (): boolean => !!navigator.userAgent.match(/Mac|Win|(Linux(?!.*Android))/);
export const isMobile = (): boolean => !isDesktop();
export const isWindows = (): boolean => navigator.userAgent.includes('Win');
