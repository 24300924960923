// @flow
import Text from 'common/ui/Text';
import { Stack } from 'common/ui/Layout';

export default function FourOFour(): React$Node {
  return (
    <Stack alignX="center" alignY="center" vertical css="flex: 1;">
      <Text variant="display4" css="font-size: 10rem;">
        404
      </Text>
      <Text variant="display4">Page Not Found</Text>
    </Stack>
  );
}
