// @flow
import styled, { css, createGlobalStyle } from 'styled-components';
import type { StyledComponent, CSSRules } from 'styled-components';
import { MentionAvatar } from 'common/ui/MentionAvatar';
import { motion } from 'framer-motion';
import { transparentize } from 'color2k';
import { Colors, Fonts } from 'styles';
import { MENU_WIDTH } from './constants';

export const floatingCss: CSSRules = css`
  position: absolute;
  width: ${MENU_WIDTH};
  z-index: 1;
  height: 100%;
  app-region: drag;
`;

export const BackdropBlur: React$ComponentType<{}> = createGlobalStyle`
  #root {
    filter: blur(4px);
  }
`;

export const SironaLogo: () => React$Node = () => (
  <img
    src="/logos/logoSide.png"
    alt="Sirona Medial Logo"
    css={`
      width: 3.8rem;
      height: 3.8rem;
      flex-shrink: 0;
      transition: filter 0.2s ease-in-out;
      &:hover,
      &:focus {
        filter: drop-shadow(2px 4px 6px #3c78bc);
      }
    `}
  />
);

export const Avatar: StyledComponent<{}, empty, typeof MentionAvatar> = styled(MentionAvatar)`
  width: 4.2rem;
  height: 4.2rem;
  background-color: ${Colors.blue1};
  cursor: pointer;
`;

export const ViewerIconContainer: StyledComponent<{}, empty, HTMLElement> = styled.div`
  position: relative;
`;

export const ViewerIndex: StyledComponent<{}, empty, HTMLElement> = styled.span`
  position: absolute;
  font-family: ${Fonts.mainFont};
  font-size: 1.2rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 100%;
  text-align: center;
  top: 0.3rem;
  left: 0;
  right: 0;
`;

const variants = {
  open: { opacity: 1, y: 0, pointerEvents: 'auto', scale: 1 },
  closed: { opacity: 0, y: '-50%', pointerEvents: 'none', scale: 1.2 },
};

export const surface = `
    width: ${MENU_WIDTH};
    background-color: ${Colors.gray2};
    z-index: 9;
  `;

const UnstyledToggleableArea = ({
  isOpen,
  children,
  className,
}: ToggleableAreaProps): React$Node => (
  <motion.nav
    animate={isOpen ? 'open' : 'closed'}
    variants={variants}
    transition={{ type: 'tween' }}
    // https://github.com/vercel/next.js/discussions/19279#discussioncomment-133229
    className={className}
  >
    {children}
  </motion.nav>
);

type ToggleableAreaProps = { isOpen: boolean, children: React$Node, className?: string };
export const ToggleableArea: StyledComponent<
  ToggleableAreaProps,
  empty,
  typeof UnstyledToggleableArea,
> = styled(UnstyledToggleableArea)`
  ${surface};
  flex: 1;
  z-index: 0;
  box-shadow: 2px 2px 2px 0 ${transparentize(Colors.gray1, 0.67)};
`;
