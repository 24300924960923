// @flow
import { PAGE_TYPES } from 'utils/pageTypes';
import type { PageTypes } from 'utils/pageTypes';
import { openPopup } from 'utils/openPopup';
import type { PopupOptions } from 'utils/openPopup';
import { openTab } from './openTab';

type OpenViewerArguments = {
  to: string,
  openedViewerIds: Set<string>,
  isReporterOpen: boolean,
  isWorklistOpen: boolean,
  isURTOpen: boolean,
  viewerIndex: string,
  getPopupOptions: (url: string) => PopupOptions,
  pageType: PageTypes,
};

export const openWindow = ({
  to,
  openedViewerIds,
  isReporterOpen,
  isWorklistOpen,
  isURTOpen,
  viewerIndex,
  getPopupOptions,
  pageType,
}: OpenViewerArguments): void => {
  const shouldOpenViewerIndex = pageType === PAGE_TYPES.VIEWER && !openedViewerIds.has(viewerIndex);
  const shouldOpenReporter = pageType === PAGE_TYPES.REPORTER && !isReporterOpen;
  const shouldOpenProductPortal = pageType === PAGE_TYPES.PRODUCTPORTAL;
  const shouldOpenWorklist = pageType === PAGE_TYPES.WORKLIST && !isWorklistOpen;
  const shouldOpenURT = pageType === PAGE_TYPES.URT && !isURTOpen;

  if (shouldOpenWorklist) {
    const isRunningAsStandaloneApp = window.matchMedia('(display-mode: standalone)').matches;

    // If the app is running as a standalone app we don't want to open the worklist in a
    // normal tab but rather in a popup, that will look like yet another standalone window
    if (isRunningAsStandaloneApp) {
      openPopup(to, getPopupOptions(to));
    } else {
      openTab(to, getPopupOptions(to));
    }
  } else if (
    shouldOpenViewerIndex ||
    shouldOpenReporter ||
    shouldOpenProductPortal ||
    shouldOpenURT
  ) {
    const url = to.replace('/viewer/0', `/viewer/${viewerIndex}`);
    openPopup(url, getPopupOptions(url));
  }
};
