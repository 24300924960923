// @flow
import { useMemo } from 'react';
import { usePriorStudiesParams } from './usePriorStudiesParams';

const useStudyIds = (): string[] => {
  const priorStudies = usePriorStudiesParams();

  const smids = useMemo(() => {
    return priorStudies?.split(',') ?? [];
  }, [priorStudies]);

  return smids;
};

export default useStudyIds;
