// @flow
import { useCurrentUser } from './useCurrentUser';
import { GET_TOOL_PREFERENCES } from 'modules/Apollo/queries';
import { useLazyQuery } from '@apollo/client';
import { NAMESPACES, sendEvent, useEventsListener } from 'modules/EventsManager';

/**
 * Enables cross-window synchronization of Viewer settings changes. Mounting this hook will
 * subscribe the window to inbound requests data refetch events.
 */
export const useViewerSettingsSync = (onSyncRequest?: () => void): void => {
  const { refetch: refetchCurrentUser } = useCurrentUser();
  const [getToolPreferences] = useLazyQuery(GET_TOOL_PREFERENCES, {
    fetchPolicy: 'network-only',
  });
  useEventsListener(NAMESPACES.CROSS_WINDOW_DATA_REFETCH, async ({ source, payload }) => {
    if (source === 'remote' && payload.type === 'user') {
      onSyncRequest?.();
      await refetchCurrentUser();
      await getToolPreferences();
    }
  });
};

/** Notify other windows to rehydrate user settings */
export const notifyUserSettingsUpdate = () => {
  sendEvent(NAMESPACES.CROSS_WINDOW_DATA_REFETCH, {
    type: 'user',
  });
};
