// @flow
import type { DocumentNode, OperationVariables } from '@apollo/client';
import { client } from './client';
import { Cache, ResponseWithContentLength } from './precacheLink';

export function getPrecacheOperationKey(
  query: DocumentNode,
  variables: OperationVariables | void
): string {
  return query.definitions[0].name.value + JSON.stringify(variables);
}

export async function precacheOperation<Q: DocumentNode, V: OperationVariables | void>(
  query: Q,
  variables: V,
  options?: { refetch?: boolean }
): Promise<?Q> {
  const { data } = await client.query<Q, V>({
    query,
    variables,
    fetchPolicy: 'no-cache',
    context: {
      precachePolicy: options?.refetch === true ? 'cache-and-network' : 'cache-first',
    },
  });
  return data;
}

export async function writePrecacheQuery<Q: DocumentNode, V: OperationVariables | void>({
  query,
  variables,
  data,
}: {
  query: Q,
  variables: V,
  data: Q,
}): Promise<void> {
  const cache = await Cache.open();

  // We use the operation name and variables as the cache key
  const operationKey = getPrecacheOperationKey(query, variables);
  const operationUrl = new URL(operationKey, window.location.origin);

  const response = new ResponseWithContentLength(JSON.stringify(data));

  // Write the response to the cache
  await cache.put(operationUrl, response);
}

export async function deletePrecacheQuery<Q: DocumentNode, V: OperationVariables | void>({
  query,
  variables,
}: {
  query: Q,
  variables: V,
}): Promise<void> {
  const cache = await Cache.open();

  // We use the operation name and variables as the cache key
  const operationKey = getPrecacheOperationKey(query, variables);
  const operationUrl = new URL(operationKey, window.location.origin);

  // Delete the response from the cache
  await cache.delete(operationUrl);
}
