// @flow
import { Colors } from 'styles';

export const MenuSeparator = (): React$Node => (
  <hr
    css={`
      border: unset;
      border-top: 1px solid ${Colors.gray5};
      margin: 0;
    `}
  />
);
