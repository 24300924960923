// @flow
import { env } from 'config/env';

export default function getGreetingTime(): string {
  var g = null;
  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = parseFloat(new Date().getHours());

  if (env.STORYBOOK_STORYSHOTS === 'true') {
    return 'test';
  }

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    g = 'afternoon';
  } else if (currentHour >= splitEvening) {
    g = 'evening';
  } else {
    g = 'morning';
  }

  return g;
}
