// @flow
import * as React from 'react';

export type CreateContextOptions<T> = {
  /**
   * If `true`, React will throw if context is `null` or `undefined`
   * In some cases, you might want to support nested context, so you can set it to `false`
   */
  strict?: boolean,
  /**
   * Error message to throw if the context is `undefined`
   */
  errorMessage?: string,
  /**
   * The display name of the context
   */
  name?: string,
  /**
   * Default value of the context
   */
  defaultValue?: T,
};

export type CreateContextReturn<T> = [React$Context<T>['Provider'], () => T, React$Context<T>];

/**
 * Creates a provider, hook, and named context.
 *
 * @param options create context options
 */
export function createContext<ContextType>(
  options: ?CreateContextOptions<ContextType>
): CreateContextReturn<ContextType> {
  const {
    strict = true,
    errorMessage = 'useContext: `context` is undefined. Seems you forgot to wrap component within the Provider',
    name,
    defaultValue,
  } = options ?? {};

  // $FlowIgnore[incompatible-call] context can have a default value and cant express this in flow
  const Context = React.createContext<ContextType>(defaultValue);

  Context.displayName = name;

  function useContext() {
    const context = React.useContext<ContextType>(Context);

    if (strict && context == null) {
      const error = new Error(errorMessage);
      error.name = 'ContextError';
      throw error;
    }

    return context;
  }

  return [Context.Provider, useContext, Context];
}
