// @flow
import { atom, useRecoilState } from 'recoil';
import type { RecoilState } from 'recoil';
import SettingsDropDown from 'common/SettingsDropDown';

import { Button } from 'common/ui/Button';
import { Stack } from 'common/ui/Layout';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { equals } from 'ramda';
import { localStoragePersisterEffect } from 'utils/recoilEffects';
import { ReporterSettingsTabContainer } from '../ReporterSettings/ReporterSettingsTabContainer';
import Text from 'common/ui/Text';
import { ReporterSettingsSectionTitle } from '../ReporterSettings/ReporterSettingsSectionTitle';
import { MenuSeparator } from 'common/ui/MenuSeparator';
import Switch from '@material-ui/core/Switch';

const audioSamplingRates = [16000, 32000, 44100, 48000];
export type AudioSamplingRate = 16000 | 32000 | 44100 | 48000;

export type ExperimentConfiguration = {
  id: 'default-2023-02-22' | 'asr-plex-default-2023-11-30' | 'custom',
  label: 'Default' | 'ASR Plex Default' | 'Custom',
  audioSamplingRate: AudioSamplingRate,
  webmEnabled: boolean,
  leadingSilenceDetectionEnabled: boolean,
  echoCancellation: boolean,
  autoGainControl: boolean,
  noiseSuppression: boolean,
};

export const DEFAULT_EXPERIMENTAL_CONFIGURATION = {
  id: 'default-2023-02-22',
  label: 'Default',
  audioSamplingRate: 44100,
  webmEnabled: true,
  leadingSilenceDetectionEnabled: false,
  echoCancellation: true,
  autoGainControl: true,
  noiseSuppression: true,
};

export const asrPlexConfiguration = {
  id: 'asr-plex-default-2023-11-30',
  label: 'ASR Plex Default',
  audioSamplingRate: 48000,
  webmEnabled: false,
  leadingSilenceDetectionEnabled: false,
  echoCancellation: true,
  autoGainControl: true,
  noiseSuppression: true,
};

// custom config are built-off the default config
export const presetExperimentConfigurations: Array<ExperimentConfiguration> = [
  {
    ...DEFAULT_EXPERIMENTAL_CONFIGURATION,
  },
  {
    ...DEFAULT_EXPERIMENTAL_CONFIGURATION,
    id: 'custom',
    label: 'Custom',
  },
];

const encodingOptions = [
  { label: 'WebM', value: true },
  { label: 'PCM', value: false },
];

export const experimentConfigurationState: RecoilState<ExperimentConfiguration> = atom({
  key: 'experimentConfigurationState',
  default: DEFAULT_EXPERIMENTAL_CONFIGURATION,
  effects: [localStoragePersisterEffect()],
});

export const ExperimentControlTab = ({
  handleOnSaveClick,
}: {
  handleOnSaveClick: () => void,
}): React$Node => {
  const [currentExperimentConfiguration, setCurrentExperimentConfiguration] = useRecoilState(
    experimentConfigurationState
  );

  const isDefault = currentExperimentConfiguration.id === DEFAULT_EXPERIMENTAL_CONFIGURATION.id;

  const handleConfigurationSelection = (configuration: ExperimentConfiguration) => {
    setCurrentExperimentConfiguration(configuration);
  };

  const handleLeadingSilenceDetection = (event: SyntheticInputEvent<HTMLInputElement>) => {
    setCurrentExperimentConfiguration((prev) => ({
      ...prev,
      id: 'custom',
      label: 'Custom',
      leadingSilenceDetectionEnabled: !prev.leadingSilenceDetectionEnabled,
      // TODO allow leadingSilenceDetection to work with webm being enabled - https://github.com/SironaMedical/services-frontend/pull/2639
      webmEnabled: prev.leadingSilenceDetectionEnabled === false ? false : prev.webmEnabled,
    }));
  };

  const handleAudioSamplingRateChange = (audioSamplingRate: number) => {
    if (!audioSamplingRates.includes(parseInt(audioSamplingRate))) {
      console.error(`audioSamplingRate is not a valid sampling rate for the browser audio.`);
      return;
    }
    setCurrentExperimentConfiguration((prev) => ({
      ...prev,
      id: 'custom',
      label: 'Custom',
      // $FlowIgnore[incompatible-call] this is explicitly checked for above
      audioSamplingRate: parseInt(audioSamplingRate),
    }));
  };

  const handleWebMToggle = (event: SyntheticInputEvent<HTMLInputElement>) => {
    setCurrentExperimentConfiguration((prev) => ({
      ...prev,
      id: 'custom',
      label: 'Custom',
      webmEnabled: !prev.webmEnabled,
      // TODO allow leadingSilenceDetection to work with webm being enabled - https://github.com/SironaMedical/services-frontend/pull/2639
      leadingSilenceDetectionEnabled:
        prev.webmEnabled === false ? false : prev.leadingSilenceDetectionEnabled,
    }));
  };

  const handleEchoCancellationToggle = (event: SyntheticInputEvent<HTMLInputElement>) => {
    setCurrentExperimentConfiguration((prev) => ({
      ...prev,
      id: 'custom',
      label: 'Custom',
      echoCancellation: !prev.echoCancellation,
    }));
  };

  const handleAutoGainControlToggle = (event: SyntheticInputEvent<HTMLInputElement>) => {
    setCurrentExperimentConfiguration((prev) => ({
      ...prev,
      id: 'custom',
      label: 'Custom',
      autoGainControl: !prev.autoGainControl,
    }));
  };

  const handleNoiseSuppressionToggle = (event: SyntheticInputEvent<HTMLInputElement>) => {
    setCurrentExperimentConfiguration((prev) => ({
      ...prev,
      id: 'custom',
      label: 'Custom',
      noiseSuppression: !prev.noiseSuppression,
    }));
  };

  // Logic required to enable us to display the correct current dropdown option for any given custom combination
  const dropdownOptions = isDefault
    ? presetExperimentConfigurations
    : [DEFAULT_EXPERIMENTAL_CONFIGURATION, currentExperimentConfiguration];

  return (
    <ReporterSettingsTabContainer
      footer={
        <Button
          variant="primary"
          type="submit"
          onClick={handleOnSaveClick}
          css={`
            margin-top: auto;
            align-self: flex-end;
          `}
          data-testid="save-reporter-settings"
        >
          Done
        </Button>
      }
    >
      <Stack vertical space="small">
        <ReporterSettingsSectionTitle title="Experiment Configuration" />
        <Text id="experiment-configuration-label">Select configuration:</Text>
        <SettingsDropDown
          data-testid="selected-experiment-configuration-label"
          menuId="select-menuitem-experiment"
          // $FlowIgnore[incompatible-type] value property works fine with objects
          value={dropdownOptions.find((option) => equals(option, currentExperimentConfiguration))}
          // $FlowIgnore[incompatible-type]
          onSelectedChange={handleConfigurationSelection}
        >
          {dropdownOptions.map((experimentConfiguration, i) => (
            <MenuItem
              id={`select-menuitem-${experimentConfiguration.id}`}
              value={experimentConfiguration}
              key={experimentConfiguration.id}
            >
              {experimentConfiguration.label}
            </MenuItem>
          ))}
        </SettingsDropDown>
      </Stack>
      <MenuSeparator />
      {!isDefault && (
        <Stack vertical space="small">
          <ReporterSettingsSectionTitle title="Custom Configuration" />
          <Stack alignX="between" alignY="center">
            <Text id="encoding-label">Encoding</Text>
            <RadioGroup
              aria-labelledby="encoding-label"
              name="encoding-radio-buttons-group"
              value={currentExperimentConfiguration.webmEnabled}
              onChange={handleWebMToggle} // TODO rename
              row={true}
            >
              {encodingOptions.map((option) => (
                <FormControlLabel
                  key={`encoding-${option.label}`}
                  value={option.value}
                  control={<Radio color="primary" />}
                  label={<Text>{option.label}</Text>}
                />
              ))}
            </RadioGroup>
          </Stack>
          <Stack alignX="between" alignY="center">
            <Text id="audio-sampling-frequency-label">Browser Audio Sampling Frequency</Text>
            <SettingsDropDown
              menuId="select-menuitem-audio-sampling"
              value={audioSamplingRates.find((option) =>
                equals(option, currentExperimentConfiguration.audioSamplingRate)
              )}
              // $FlowIgnore[incompatible-type]
              onSelectedChange={handleAudioSamplingRateChange}
            >
              {audioSamplingRates.map((rate, i) => (
                <MenuItem id={`select-menuitem-${rate}`} value={rate} key={`audio-${rate}hz`}>
                  {rate}hz
                </MenuItem>
              ))}
            </SettingsDropDown>
          </Stack>
          <Stack alignX="between" alignY="center">
            <FormControlLabel
              data-testid="echo-cancellation-radio"
              control={<Switch color="primary" />}
              label={<Text>Echo Cancellation</Text>}
              labelPlacement="start"
              css="margin-left: 0; justify-content: space-between; width: 100%;"
              onChange={handleEchoCancellationToggle}
              checked={currentExperimentConfiguration.echoCancellation}
            />
          </Stack>
          <Stack alignX="between" alignY="center">
            <FormControlLabel
              data-testid="auto-gain-control-radio"
              aria-labelledby="auto-gain-control-label"
              control={<Switch color="primary" />}
              label={<Text>Auto Gain Control</Text>}
              labelPlacement="start"
              css="margin-left: 0; justify-content: space-between; width: 100%;"
              onChange={handleAutoGainControlToggle}
              checked={currentExperimentConfiguration.autoGainControl}
            />
          </Stack>
          <Stack alignX="between" alignY="center">
            <FormControlLabel
              data-testid="noise-suppression-radio"
              aria-labelledby="noise-suppression-label"
              control={<Switch color="primary" />}
              label={<Text>Noise Suppression</Text>}
              labelPlacement="start"
              css="margin-left: 0; justify-content: space-between; width: 100%;"
              onChange={handleNoiseSuppressionToggle}
              checked={currentExperimentConfiguration.noiseSuppression}
            />
          </Stack>
          <Stack alignX="between" alignY="center">
            <FormControlLabel
              data-testid="noise-suppression-radio"
              aria-labelledby="noise-suppression-label"
              control={<Switch color="primary" />}
              label={<Text>Leading Silence Detection</Text>}
              labelPlacement="start"
              css="margin-left: 0; justify-content: space-between; width: 100%;"
              onChange={handleLeadingSilenceDetection}
              checked={currentExperimentConfiguration.leadingSilenceDetectionEnabled}
            />
          </Stack>
        </Stack>
      )}
    </ReporterSettingsTabContainer>
  );
};
