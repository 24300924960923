// @flow
import { useState } from 'react';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import ExtensionIcon from '@material-ui/icons/Extension';
import { Menu } from 'common/ui/Menu';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
  ListSubheader,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as SettingIconSVG } from 'assets/settingIcon.svg';
import {
  APP_VERSION as _APP_VERSION,
  APP_BUILD as _APP_BUILD,
  CHROME_EXTENSION_INSTALL_PAGE,
  EMAILS,
} from 'config/constants';
import Text from 'common/ui/Text';
import { Stack } from '../Layout';
import { env } from 'config/env';
import { Barcode } from 'common/Account/Barcode';
import { isWorkspaceManagerAvailable } from 'modules/workspace-manager';
import type { PositioningStrategy } from '@popperjs/core/lib/types';
import { useDisplaySystemCheck } from 'common/SystemCheck/useSystemCheck';

const APP_VERSION = env.NODE_ENV === 'test' ? 'test' : _APP_VERSION;
const APP_BUILD = env.NODE_ENV === 'test' ? '??????' : _APP_BUILD;

type MenuProfilePopperProps = {
  displayName: ?string,
  onSettingClick: () => void,
  onLogoutClick: () => void,
  children: React$Node,
  visible?: boolean,
  strategy?: PositioningStrategy,
};

export const SironaWorkspaceInfo = (): React$Node => {
  return (
    <Stack
      space="large"
      alignX="center"
      alignY="center"
      vertical
      css={`
        text-align: center;
      `}
    >
      <img
        alt="Sirona Medical"
        src="/logos/logoMain.png"
        css={`
          max-height: 90px;
          max-width: 337.5px;
        `}
      />

      <div
        css={`
          font-weight: 500;
        `}
      >
        {!!APP_VERSION && (
          <div>
            <Text variant="body1">Version: {APP_VERSION}</Text>
          </div>
        )}
        {!!APP_BUILD && (
          <div>
            <Text variant="body1">Build: {APP_BUILD}</Text>
          </div>
        )}
      </div>

      <div
        css={`
          font-weight: 500;
        `}
      >
        <div>
          <Text variant="body1">
            General support: use in-product chat or{' '}
            <Text as="a" color="link" href={`mailto:${EMAILS.SUPPORT}`}>
              {EMAILS.SUPPORT}
            </Text>
          </Text>
        </div>

        <div>
          <Text variant="body1">
            IT support:{' '}
            <Text as="a" color="link" href={`mailto:${EMAILS.ITSUPPORT}`}>
              {EMAILS.ITSUPPORT}
            </Text>
          </Text>
        </div>
      </div>

      <div>
        <div>
          <Text variant="body2">55 2nd St Suite 2600</Text>
        </div>
        <div>
          {' '}
          <Text variant="body2">San Francisco, CA 94103</Text>
        </div>
      </div>
      <div>
        <Barcode />
      </div>
      <Text variant="small">
        Copyright &copy; 2018-{new Date().getFullYear()} Sirona Medical, Inc.
      </Text>
    </Stack>
  );
};

export const MenuProfilePopper: React$ComponentType<MenuProfilePopperProps> = ({
  displayName,
  onSettingClick,
  onLogoutClick,
  children,
  visible,
  strategy = 'absolute',
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const { showSystemCheckScreen } = useDisplaySystemCheck();

  const onClose = () => {
    setShowDialog(false);
  };
  return (
    <>
      <Menu
        trigger="click"
        offset={[0, 15]}
        placement="right-start"
        visible={visible}
        strategy={strategy}
        data-testid="menu-profile"
        render={() => (
          <List
            dense
            subheader={<ListSubheader>{displayName}</ListSubheader>}
            component="nav"
            css="width: 21rem"
            data-testid="menu-profile-list"
          >
            <ListItem button onClick={() => setShowDialog(true)}>
              <ListItemText>About Sirona Workspace</ListItemText>
            </ListItem>
            <Divider css="margin: 1rem 0" />
            <ListItem button onClick={onSettingClick} data-testid="profile-settings">
              <ListItemIcon>
                <SettingIconSVG />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </ListItem>
            {!isWorkspaceManagerAvailable() && (
              <a
                css="all: unset; display: content;"
                target="_blank"
                rel="noreferrer noopener"
                href={CHROME_EXTENSION_INSTALL_PAGE}
              >
                <ListItem button>
                  <ListItemIcon>
                    <ExtensionIcon />
                  </ListItemIcon>
                  <ListItemText>Install extension</ListItemText>
                </ListItem>
              </a>
            )}
            <ListItem button onClick={showSystemCheckScreen}>
              <ListItemIcon>
                <PlaylistAddCheck />
              </ListItemIcon>
              <ListItemText>System check</ListItemText>
            </ListItem>
            <ListItem button onClick={onLogoutClick} data-testid="signout">
              <ListItemIcon>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText>Sign out</ListItemText>
            </ListItem>
          </List>
        )}
      >
        {children}
      </Menu>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={showDialog}
        onClose={onClose}
        css={`
          .MuiPaper-root {
            max-width: 398px;
          }
          .MuiDialog-paper {
            padding: 0.5rem 3rem 3rem 3rem;
          }
        `}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            css={`
              position: absolute;
              right: 1rem;
              top: 1rem;
            `}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <SironaWorkspaceInfo />
        </DialogContent>
      </Dialog>
    </>
  );
};
