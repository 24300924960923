// @flow
import { FF, useSplitFlags } from 'modules/feature-flags';
import { logger } from 'modules/logger';
import { useEffect, useState } from 'react';
import { equals } from 'ramda';
import { DEPLOY_ENV } from 'config';

export const useGetCurrentUserFeatureFlagsDev = (): ?{ [x: string]: string } => {
  const [loadedFlags] = useSplitFlags(Object.keys(FF));

  // Only updates when a change in flags is detected
  const [flags, setFlags] = useState(null);

  useEffect(() => {
    if (!equals(flags, loadedFlags)) {
      setFlags(loadedFlags);
    }
  }, [flags, loadedFlags]);

  // Log the feature flags in non-production environments, if available
  useEffect(() => {
    if (flags != null && DEPLOY_ENV !== 'production') {
      logger.info('current user feature flags:', flags);
    }
  }, [flags]);

  return flags;
};

const useGetCurrentUserFeatureFlagsProd = () => {};

export const useGetCurrentUserFeatureFlags: (() => void) | (() => ?{ [x: string]: string }) =
  DEPLOY_ENV !== 'production'
    ? useGetCurrentUserFeatureFlagsDev
    : useGetCurrentUserFeatureFlagsProd;
