// @flow
import { useMutation } from '@apollo/client';
import type { VirtualMonitorSplit } from 'generated/graphql';
import { TOGGLE_VIRTUAL_MONITOR_SPLIT } from 'modules/Apollo/queries';
import { useCurrentUser } from './useCurrentUser';
import { GET_ME } from '../modules/Apollo/queries';
import { assocPath } from 'ramda';
import { notifyUserSettingsUpdate } from './useViewerSettingsSync';

export const useVirtualMonitorSplitToggle = (): [
  VirtualMonitorSplit | null,
  (VirtualMonitorSplit | null) => Promise<void>,
] => {
  const { data, refetch: refetchUser } = useCurrentUser();
  const me = data?.me;
  const virtualMonitorSplit = me?.viewerSettings?.virtualMonitorSplit ?? null;

  const [saveVirtualMonitorSplitValue] = useMutation(TOGGLE_VIRTUAL_MONITOR_SPLIT, {
    onCompleted: () => {
      // refetchUser();
      notifyUserSettingsUpdate();
    },
    onError: () => {
      refetchUser();
    },
    update(cache, res, { variables }) {
      if (me == null) {
        return;
      }

      const { virtualMonitorSplit } = variables || {
        virtualMonitorSplit: null,
      };

      cache.writeQuery({
        query: GET_ME,
        data: assocPath(['me', 'viewerSettings', 'virtualMonitorSplit'], virtualMonitorSplit, data),
      });
    },
  });

  const toggleVirtualMonitorSplit = async (virtualMonitorSplit: VirtualMonitorSplit | null) => {
    await saveVirtualMonitorSplitValue({
      variables: {
        virtualMonitorSplit,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        toggleVirtualMonitorSplit: {
          virtualMonitorSplit,
          __typename: 'VirtualMonitorSplitResponse',
        },
      },
    });
  };

  return [virtualMonitorSplit, toggleVirtualMonitorSplit];
};
