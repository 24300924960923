// @flow

import { useSplitFlag } from './useSplitFlag';

/**
 * NOTE: uses Suspense, make sure to handle it accordingly
 */
export function useFeatureFlagEnabled(flagName: string): boolean {
  return useSplitFlag(flagName, 'off', (flag) => flag === 'on');
}
