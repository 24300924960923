// @flow
import { MuiThemeProvider, createMuiTheme, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { transparentize } from 'color2k';
import { Colors } from 'styles';

export const rootTheme = {
  themeName: 'Sirona Default',
  typography: {
    htmlFontSize: 10,
    useNextVariants: true,
  },
  palette: {
    type: 'dark',
    preferredHighlight: Colors.blue2,
    borderDefaultColor: '#0D47A1',
    borderActiveColor: '#2196F3',
    primary: { main: Colors.blue3 },
    background: {
      default: Colors.gray1,
    },
  },
  overrides: {
    MuiTypography: {
      h3: {
        fontSize: 16,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: Colors.gray2,
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: Colors.blue3,
          '&:hover': {
            backgroundColor: (transparentize(Colors.blue3, 0.8): string),
          },
        },

        '&$checked + $track': {
          backgroundColor: Colors.blue5,
        },
      },
    },
  },
};

export const muiRootTheme: mixed = createMuiTheme(rootTheme);

export const ThemeProviders = ({ children }: { children: React$Node }): React$Node => {
  // Do all app level theme-ing here
  // MuiThemeProvider makes the theme available down the React tree
  // thanks to React context.
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={muiRootTheme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StylesProvider>
  );
};

function withRoot<T>(Component: React$ComponentType<T>): React$ComponentType<T> {
  function WithRoot(props: T) {
    return (
      <ThemeProviders>
        <Component {...props} />
      </ThemeProviders>
    );
  }

  return WithRoot;
}

export default withRoot;
