// @flow

import { useApolloClient } from '@apollo/client';

import localforage from 'localforage';
import { purgePrecache } from 'modules/Apollo/precacheLink';
import { clearDB as clearPixelDB } from 'modules/viewer/pixelCache';
import { useLayoutEffect } from 'react';

export const clearStoredData = async () => {
  try {
    // Clear localforage cache on logout so that we don't keep stale data and we allow users to
    // login with a different account and start from scratch
    // this is a temporary security mitigation until we have encrypted local storage
    await localforage.clear();
  } catch (e) {
    console.error('problem clearing local storage on logout', e);
  }
  try {
    // Clear precache on logout so that we don't keep stale data and we allow users to
    // login with a different account and start from scratch
    // this is a temporary security mitigation until we have encrypted local storage
    await purgePrecache();
  } catch (e) {
    console.error('problem clearing precache on logout', e);
  }
  try {
    // Clear pixel db on logout so that we don't keep stale data and we allow users to
    // login with a different account and start from scratch
    // this is a temporary security mitigation until we have encrypted local storage
    await clearPixelDB();
  } catch (e) {
    console.error('problem clearing pixel data on logout', e);
  }
  // clear old items
  try {
    localStorage.removeItem('apollo-cache-persist');
    const cacheKeys = await caches.keys();
    await Promise.all(cacheKeys.map((key) => caches.delete(key)));
  } catch (e) {
    console.error('problem clearing old caches', e);
  }
};

export const useClearStoredDataOnClose = (): void => {
  const apolloClient = useApolloClient();

  useLayoutEffect(() => {
    const listener = window.addEventListener('pagehide', (event) => {
      clearStoredData();
      try {
        apolloClient.cache.reset();
        apolloClient.resetStore();
      } finally {
      }
    });
    return window.removeEventListener('pagehide', listener);
  }, [apolloClient]);
};
