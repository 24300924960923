// @flow
import Colors from './colors';
import Sizes from './sizes';

const baseButton = {
  boxSizing: 'border-box',
  color: Colors.white,
  textTransform: 'unset',
  boxShadow: 'unset',
  fontSize: Sizes.textButton,
  borderRadius: 0,
  border: 'none',
};

const buttons = {
  baseButton,
  blueButton: {
    ...baseButton,
    background: Colors.blue2,
    '&:focus, &:hover': {
      background: Colors.blue3,
    },
  },
  redButton: {
    ...baseButton,
    background: Colors.red2,
    '&:focus, &:hover': {
      background: Colors.red1,
    },
  },
  yellowButton: {
    ...baseButton,
    color: Colors.gray1,
    background: Colors.yellow3,
    '&:focus, &:hover': {
      background: Colors.yellow3,
    },
  },
  grayButton: {
    ...baseButton,
    borderRadius: 6,
    background: Colors.gray5,
    '&[disabled]': {
      background: Colors.gray5,
      color: Colors.gray8,
      border: 'none',
    },
    '&:focus, &:hover': {
      background: Colors.gray5,
    },
  },
  disabledRoundedButton: {
    ...baseButton,
    borderRadius: 6,
    '&[disabled]': {
      background: 'transparent',
      color: Colors.gray6,
      border: `1px solid ${Colors.gray7}`,
    },
  },
  roundedButtonForToolBar: {
    ...baseButton,
    height: 32,
    borderRadius: 16,
    padding: '0px 16px',
    background: Colors.toolbarButtonNormal,
    '&:focus, &:hover': {
      background: Colors.mainBlue,
    },
  },
  roundedActiveButtonForToolBar: {
    ...baseButton,
    height: 32,
    borderRadius: 16,
    padding: '0px 16px',
    background: Colors.mainBlue,
    '&:focus, &:hover': {
      background: Colors.mainBlueHover,
    },
  },
  activeButton: {
    ...baseButton,
    background: Colors.mainBlue,
    '&:focus, &:hover': {
      background: Colors.mainBlueHover,
    },
  },
  button: {
    ...baseButton,
    height: 40,
    background: Colors.mainBlue,
    borderRadius: 6,
    '&:focus, &:hover': {
      background: Colors.mainBlueHover,
    },
    '&:disabled': {
      opacity: 0.48,
    },
  },
};

export default buttons;
