// @flow
import styled from 'styled-components';
import type { StyledComponent, PropsWithTheme } from 'styled-components';
import { TextCss, Colors } from 'styles';
import type { TextVariant } from 'styles/text';

// TODO(jnelson): import from Spacing module once merged
// https://github.com/SironaMedical/services-frontend/pull/1359
const spacings = {
  none: 0,
  xxsmall: 0.2,
  xsmall: 0.5,
  small: 1,
  medium: 1.5,
  large: 2.2,
  xlarge: 3.5,
  xxlarge: 5,
};

const COLOR_MAP = {
  primary: 'gray10',
  secondary: 'gray9',
  'secondary-alt': 'gray8',
  success: 'green5',
  error: 'red5',
  black: 'gray1',
  link: 'blue5',
};

type TextOverflow = 'clip' | 'ellipsis' | 'show';

export type TextProps = $ReadOnly<{
  variant?: TextVariant,
  display?: 'none' | 'inline' | 'block' | 'inline-block' | 'inline-flex',
  color?: string,
  italic?: boolean,
  gutterBottom?: $Keys<typeof spacings>,
  gutterTop?: $Keys<typeof spacings>,
  children?: React$Node,
  overflow?: TextOverflow,
  ...
}>;

const TEXT_ALIGN = ({ align = 'inherit' }: PropsWithTheme<TextProps, empty>) => align;
const DISPLAY = ({ display = 'inline' }: PropsWithTheme<TextProps, empty>) => display;
const GUTTER_BOTTOM = ({ gutterBottom = 'none' }: PropsWithTheme<TextProps, empty>) =>
  `${spacings[gutterBottom]}rem`;
const GUTTER_TOP = ({ gutterTop = 'none' }: PropsWithTheme<TextProps, empty>) =>
  `${spacings[gutterTop]}rem`;
const COLOR = ({ color = 'primary' }: PropsWithTheme<TextProps, empty>) =>
  Colors[COLOR_MAP[(color: $FlowFixMe)] ?? color];
const FONT_STYLE = ({ italic = false }: PropsWithTheme<TextProps, empty>) =>
  italic ? 'italic' : '';

const Text: StyledComponent<TextProps, empty, HTMLSpanElement> = styled.span.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    defaultValidatorFn(prop) || ['color'].includes(prop),
})`
  display: ${DISPLAY};
  text-align: ${TEXT_ALIGN};
  margin-bottom: ${GUTTER_BOTTOM};
  margin-top: ${GUTTER_TOP};
  color: ${COLOR};
  ${FONT_STYLE}
  ${(props: PropsWithTheme<TextProps, empty>) => TextCss[props.variant ?? 'body1']};
  ${(props: PropsWithTheme<TextProps, empty>) =>
    props.overflow === 'ellipsis'
      ? 'text-overflow: ellipsis; white-space: nowrap; overflow: hidden;'
      : ''};
  ${(props: PropsWithTheme<TextProps, empty>) =>
    props.overflow === 'clip' ? 'overflow: hidden;' : ''};
  ${(props: PropsWithTheme<TextProps, empty>) =>
    props.overflow === 'show' ? 'overflow: visible;' : ''};
`;

export default Text;
