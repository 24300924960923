// @flow
import { useCallback, useRef } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_WORK_LIST_ITEM, GET_LAST_VIEWED_WORKLISTS } from 'modules/Apollo/queries';
import type {
  GetWorkListItemQuery,
  GetWorkListItemQueryVariables,
  GetLastViewedWorklistsQuery,
  GetLastViewedWorklistsQueryVariables,
} from 'generated/graphql';
import useStudyIds from 'hooks/useStudyIds';
import { PATH } from 'config/constants';
import { useAsyncGlobalAction } from 'hooks/useGlobalAction';
import { useOpenTabs } from './useOpenTabs';
import { NOT_FOUND_CASE_ID } from '../config/constants';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { usePrevious } from 'react-use';
import { useUrlBuilder } from './useUrlBuilder';
import { clearProviderMap } from 'modules/viewer/workers/PixelDataSharedWorker';
import { saveWindowSyncInfo } from 'modules/windowDesyncDetector';
import { logger } from 'modules/logger';

type HookResult = {
  currentCaseId: ?string,
  currentCase: ?GetWorkListItemQuery['workListItem'],
  refreshCase: () => Promise<void>,
  loadingCase: boolean,
};

export const useCurrentComparativeStudies = (): [
  Array<string>,
  ({ caseSmid?: string, studySmids: Array<string> }) => mixed,
] => {
  const currentCaseId = useCurrentCaseId();
  const studySmids = useStudyIds();
  const buildUrl = useUrlBuilder();
  const navigate = useNavigate();
  const previousCaseId = usePrevious(currentCaseId);
  const [shouldRefreshViewer] = useFeatureFlagEnabled(FF.refresh_viewer_on_new_case);

  const setCurrentComparativeStudies = useCallback(
    ({ caseSmid = currentCaseId, studySmids }) => {
      if (caseSmid == null) return;
      const url = buildUrl({ caseSmid, studySmids });
      if (url == null) return;

      if (previousCaseId === caseSmid) navigate(url);
      else {
        if (shouldRefreshViewer) window.location.href = url;
        else {
          if (window.location.pathname !== url) {
            clearProviderMap();
            navigate(url);
          }
        }
      }
    },
    [buildUrl, currentCaseId, shouldRefreshViewer, navigate, previousCaseId]
  );

  return [studySmids, setCurrentComparativeStudies];
};

export const useCurrentCaseId = (): ?string => {
  // Highest priority as it has less chances of being stale
  const viewerUrlWorklistId = useMatch(PATH.VIEWER)?.params?.worklistId;
  const reporterUrlWorklistId = useMatch(PATH.REPORTER)?.params?.worklistId;
  const patientJacketUrlWorklistId = useMatch(PATH.PATIENT_JACKET)?.params?.smid;

  // Right after the url, as it may have a small delay and result stale
  const openTabs = useOpenTabs();
  const worklistIds = openTabs.map((tab) => tab.worklistIds).flat(1);
  const { data: lastViewedWorklistsData } = useQuery<
    GetLastViewedWorklistsQuery,
    GetLastViewedWorklistsQueryVariables,
  >(GET_LAST_VIEWED_WORKLISTS, { skip: worklistIds[0] != null, fetchPolicy: 'cache-and-network' });

  // Lowest priority as it comes from network and it's likely to be stale
  const lastViewedWorklistSmid = lastViewedWorklistsData?.me?.lastViewedWorklists[0]?.smid;

  // Using the first one here will reduce the chances of having a stale id and end up
  // rerendering unnecessarily
  const currentCaseId =
    viewerUrlWorklistId ??
    reporterUrlWorklistId ??
    patientJacketUrlWorklistId ??
    worklistIds[0] ??
    lastViewedWorklistSmid;

  return currentCaseId;
};

/** Builds and navigates to the URL with the caseId */
export const useSetCurrentCase = (): ((smid: string) => mixed) => {
  const navigate = useNavigate();
  const buildUrl = useUrlBuilder();
  const [shouldRefreshViewer] = useFeatureFlagEnabled(FF.refresh_viewer_on_new_case);

  return useCallback(
    (caseSmid: string) => {
      if (caseSmid == null) return;
      const url = buildUrl({ caseSmid });
      if (url == null) return;
      if (window.location.pathname !== url) {
        clearProviderMap();
        if (shouldRefreshViewer) {
          saveWindowSyncInfo(caseSmid);
          window.location.href = url;
        } else {
          navigate(url);
          saveWindowSyncInfo(caseSmid);
        }
      }
    },
    [buildUrl, navigate, shouldRefreshViewer]
  );
};

export const useCurrentCase = (): HookResult => {
  const currentCaseId = useCurrentCaseId();
  const {
    data: caseData,
    loading: loadingCase,
    refetch,
  } = useQuery<GetWorkListItemQuery, GetWorkListItemQueryVariables>(GET_WORK_LIST_ITEM, {
    variables: currentCaseId == null ? undefined : { smid: currentCaseId },
    skip: currentCaseId == null || currentCaseId === NOT_FOUND_CASE_ID,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const refetchWorkListItem = useCallback(async () => {
    if (currentCaseId != null) {
      logger.info('[Current Case]: refetching case');

      await refetch({ smid: currentCaseId });
    }
  }, [currentCaseId, refetch]);

  const prevData = useRef(caseData);
  if (caseData?.workListItem?.smid !== prevData.current?.workListItem?.smid) {
    prevData.current = caseData;
  }
  const currentCase = (caseData || prevData.current)?.workListItem;

  const refreshCase = useAsyncGlobalAction('use-current-case', refetchWorkListItem);

  return {
    currentCaseId,
    currentCase,
    refreshCase,
    loadingCase: loadingCase && currentCase?.smid !== currentCaseId,
  };
};
