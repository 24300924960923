// @flow
import { BroadcastChannel } from 'broadcast-channel';

export type BroadcastMessage = { type: string };

// $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
const channelsMap = new Map<string, BroadcastChannel<any>>();
const getBroadcastChannel = <T>(key: string): BroadcastChannel<T> => {
  const bc = channelsMap.get(key);
  if (bc != null) {
    return bc;
  } else {
    const newBc = new BroadcastChannel<T>(key);
    channelsMap.set(key, newBc);
    return newBc;
  }
};

export default function useBroadcastChannel<T = mixed>(key: string): BroadcastChannel<T> {
  return getBroadcastChannel<T>(key);
}
