// @flow
import type { Instance } from '@popperjs/core';
import type { State } from '@popperjs/core/lib/types';

// $FlowFixMe[untyped-import] (automated-migration-2022-01-19)
import maxSize from 'popper-max-size-modifier';

export { maxSize };

type ApplyMaxSizeOptions = {
  tippy?: boolean,
};

export const applyMaxSize = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'write',
  fn({
    state,
    instance,
    options,
  }: {
    state: State,
    instance: Instance,
    options: ApplyMaxSizeOptions,
    ...
  }) {
    const { tippy } = options;
    const { height } = state.modifiersData.maxSize;

    if (tippy === true && state.elements.popper.firstElementChild instanceof HTMLElement) {
      state.elements.popper.firstElementChild.style.maxHeight = `${height}px`;
    } else {
      state.styles.popper.maxHeight = `${height}px`;
    }
  },
};

type SameWidthOptions = {
  narrowerPopper?: boolean,
};

export const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  options: { narrowerPopper: false },
  phase: 'beforeWrite',
  requires: ['computeStyles'],

  fn: ({ state, options }: { state: State, options: SameWidthOptions, ... }) => {
    const check =
      options.narrowerPopper === true
        ? state.rects.popper.width < state.rects.reference.width
        : true;
    if (check) {
      state.styles.popper.width = `${state.rects.reference.width}px`;
    }
  },
  effect: ({ state, options }: { state: State, options: SameWidthOptions, ... }) => {
    if (options.narrowerPopper !== true) {
      state.elements.popper.style.width = `${state.elements.reference?.clientWidth ?? 0}px`;
    }
  },
};
