// @flow
import { datadogRum } from '@datadog/browser-rum';
import { SplitFactory } from '@splitsoftware/splitio';
import type { SplitSDK, SplitFactoryConfig } from '@splitsoftware/splitio';
import { env } from 'config/env';
import { SPLITIO_API_KEY } from 'config';
import { importLocalSplits } from 'config/importLocalSplits';

// See local.split.yaml to change feature flags locally
// or run `yarn run -T cp-local-feature-flags if you haven't set this up yet
const features: SplitFactoryConfig['features'] | void =
  env.LOCAL_SPLITS === 'true' ? importLocalSplits() : undefined;

export class SplitIOManager {
  static factory: SplitSDK;

  static createSplitFactory: (email: string) => void = (email) => {
    this.factory = SplitFactory({
      core: {
        authorizationKey: SPLITIO_API_KEY,
        key: email,
      },
      features,
      startup: {
        readyTimeout: 5, // 5 sec (value recommended by Split.io support)
      },
      trafficType: 'user',
      impressionListener: {
        logImpression: (impressionData) => {
          datadogRum.addFeatureFlagEvaluation(
            impressionData.impression.feature,
            impressionData.impression.treatment
          );
        },
      },
    });
  };

  static getFactory(email: string): SplitSDK {
    if (this.factory == null) {
      this.createSplitFactory(email);
    }
    return this.factory;
  }
}

export const getFactory = (email: string): SplitSDK => {
  return SplitIOManager.getFactory(email);
};
