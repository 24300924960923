/* eslint-disable no-redeclare */
// @flow

/**
 * Use this if you do not want to throw an error but still capture it.
 */
export const unreachableCaseLog = (x: string): void => {
  console.error(`Unreachable case: ${x}`);
};

export const unreachableCaseError = <T>(x: string): T => {
  throw new Error(`Unreachable case: ${x}`);
};
