// @flow
import analytics from 'modules/analytics';
import { globalContext } from 'modules/analytics/constants';
import { Cache } from './precacheLink';
import type { Json } from 'generated/graphql';
import type { DocumentNode } from '@apollo/client';

export async function emitPrecacheStatus<T: Json>(
  query: DocumentNode,
  variables: T
): Promise<void> {
  const cache = await Cache.open();
  const operationKey = query.definitions[0].name.value + JSON.stringify(variables);
  const operationUrl = new URL(operationKey, window.location.origin);
  const cacheResponse = await cache.match(operationUrl);
  analytics.addContext(globalContext.viewer.precacheStatus, {
    source: cacheResponse ? 'precache' : 'network',
    worklistIds: variables.worklistIds,
  });
}
