// @flow
import type { CustomIconKey } from 'hooks/usePreferences/customIcons';
import type { GetToolsQuery, ToolInteractionUnion, ToolCategory } from 'generated/graphql';

import { useAsync } from 'react-use';
import { client as apolloClient } from 'modules/Apollo/client';
import { GET_TOOLS } from 'modules/Apollo/queries';
import memoize from 'lodash.memoize';
import { ToolInteractionTypeValues } from 'generated/graphql';
import { TOOL_RESTRICTIONS } from 'domains/viewer/ViewportDre/constants';
import { FF } from 'modules/feature-flags';

export type ToolID = string;

export type BuiltInToolIconName =
  | 'visibility'
  | 'pan_tool'
  | 'zoom_in'
  | 'swap_horizontal'
  | 'swap_vertical'
  | 'exposure'
  | 'straighten'
  | 'share'
  | 'code'
  | 'panorama_fish_eye'
  | 'adjust'
  | 'invert_colors'
  | 'cached'
  | 'find_in_page'
  | 'crop_square'
  | 'play_arrow'
  | 'delete'
  | 'vertical_split'
  | 'location_searching'
  | 'layers'
  | 'border_color'
  | 'font_download'
  | '3d_rotation'
  | 'anatomic_navigator'
  | 'check_circle'
  | 'delete_icon'
  | 'fiber_manual_record'
  | 'skip_previous'
  | 'skip_next'
  | 'manualLinkedScroll'
  | 'automaticLinkedScroll'
  | 'arrow'
  | 'key_image'
  | 'toggle_tool_bar'
  | 'toggle_thumbnail_bar'
  | string;

export type IconName = CustomIconKey | BuiltInToolIconName | string;

type SDKToolKey = { key: string, interactionId: string };

type BuiltInToolKey = { key: string };

export const TOOL_CATEGORIES: { [category: ToolCategory]: string } = {
  IMAGE_NAVIGATION: 'Mouse and Tool Wheel Preset',
  IMAGE_ADJUSTMENT: 'Image Adjustment',
  ANNOTATIONS: 'Annotations',
  WORKFLOW: 'Workflow',
  REPORTER: 'Reporter',
};

export type Tool = {
  id: ToolID,
  iconName: IconName,
  secondaryIconName?: string,
  name: string,
  isMouseAdaptor: boolean,
  isScrollAdaptor?: boolean,
  hideFromToolbar?: boolean,
  isRightMouseButtonAllowed?: boolean,
  description: string,
  secondaryDescription?: string,
  keys?: Array<SDKToolKey | BuiltInToolKey>,
  splitFlag?: string,
  source?: 'sdk' | 'built-in',
  svgIcon?: string,
  category: ToolCategory,
  lockedKey?: boolean,
  restrictions?: Array<number>,
};

export const builtInTools: { [key: string]: Tool } = {
  pan: {
    id: 'PAN',
    iconName: 'pan_tool',
    name: 'pan',
    isMouseAdaptor: true,
    isRightMouseButtonAllowed: true,
    description: 'Pan',
    category: 'IMAGE_ADJUSTMENT',
  },
  zoom: {
    id: 'ZOOM',
    iconName: 'zoom_in',
    name: 'zoom',
    isMouseAdaptor: true,
    isRightMouseButtonAllowed: true,
    description: 'Zoom',
    category: 'IMAGE_ADJUSTMENT',
  },
  horizontalFlip: {
    id: 'HORIZONTAL_FLIP',
    iconName: 'swap_horizontal',
    name: 'horizontalFlip',
    isMouseAdaptor: false,
    description: 'Horizontal Flip',
    category: 'IMAGE_ADJUSTMENT',
  },
  verticalFlip: {
    id: 'VERTICAL_FLIP',
    iconName: 'swap_vertical',
    name: 'verticalFlip',
    isMouseAdaptor: false,
    description: 'Vertical Flip',
    category: 'IMAGE_ADJUSTMENT',
  },
  anatomicNavigator: {
    id: 'ANATOMIC_NAVIGATOR',
    iconName: 'anatomic_navigator',
    name: 'anatomicNavigator',
    isMouseAdaptor: true,
    description: 'Anatomic navigator',
    category: 'ANNOTATIONS',
  },
  wwwc: {
    id: 'WWWC',
    iconName: 'exposure',
    name: 'wwwc',
    isMouseAdaptor: true,
    isRightMouseButtonAllowed: true,
    description: 'W/L',
    category: 'IMAGE_ADJUSTMENT',
  },
  length: {
    id: 'LENGTH',
    iconName: 'straighten',
    name: 'length',
    isMouseAdaptor: true,
    description: 'Length',
    category: 'ANNOTATIONS',
  },
  angle: {
    id: 'ANGLE',
    iconName: 'share',
    name: 'angle',
    isMouseAdaptor: true,
    description: 'Angle',
    category: 'ANNOTATIONS',
  },
  cobbAngle: {
    id: 'COBB_ANGLE',
    iconName: 'code',
    name: 'cobbAngle',
    isMouseAdaptor: true,
    description: 'Cobb Angle',
    category: 'ANNOTATIONS',
  },
  ellipse: {
    id: 'ELLIPSE',
    iconName: 'panorama_fish_eye',
    name: 'ellipse',
    isMouseAdaptor: true,
    description: 'Ellipse',
    category: 'ANNOTATIONS',
  },
  circle: {
    id: 'CIRCLE',
    iconName: 'adjust',
    name: 'circle',
    isMouseAdaptor: true,
    description: 'Circle',
    category: 'ANNOTATIONS',
  },
  invert: {
    id: 'INVERT',
    iconName: 'invert_colors',
    name: 'invert',
    isMouseAdaptor: false,
    description: 'Invert',
    category: 'IMAGE_ADJUSTMENT',
  },
  freeRotate: {
    id: 'FREE_ROTATE',
    iconName: 'cached',
    name: 'freeRotate',
    isMouseAdaptor: true,
    isRightMouseButtonAllowed: true,
    description: 'Free Rotate',
    category: 'IMAGE_ADJUSTMENT',
  },
  magnifyGlass: {
    id: 'MAGNIFY_GLASS',
    iconName: 'find_in_page',
    name: 'magnifyGlass',
    isMouseAdaptor: true,
    description: 'Magnify glass',
    category: 'IMAGE_ADJUSTMENT',
  },
  rectangle: {
    id: 'RECTANGLE',
    iconName: 'crop_square',
    name: 'rectangle',
    isMouseAdaptor: true,
    description: 'Rect',
    category: 'ANNOTATIONS',
  },
  arrow: {
    id: 'ARROW',
    iconName: 'arrow',
    name: 'arrow',
    isMouseAdaptor: true,
    description: 'Arrow',
    category: 'ANNOTATIONS',
  },
  rotate: {
    id: 'ROTATE',
    iconName: '3d_rotation',
    name: 'rotate',
    isMouseAdaptor: true,
    description: 'Rotate',
    category: 'IMAGE_ADJUSTMENT',
  },
  rotate90: {
    id: 'ROTATE_90',
    iconName: 'rotate90',
    name: 'rotate90',
    isMouseAdaptor: true,
    description: 'Rotate 90˚',
    category: 'IMAGE_ADJUSTMENT',
  },
  cine: {
    id: 'CINE',
    iconName: 'play_arrow',
    secondaryIconName: 'stop',
    name: 'cine',
    isMouseAdaptor: false,
    description: 'Cine',
    secondaryDescription: 'Stop Cine',
    category: 'IMAGE_NAVIGATION',
  },
  eraser: {
    id: 'ERASER',
    iconName: 'erase',
    name: 'eraser',
    isMouseAdaptor: true,
    description: 'Eraser',
    category: 'ANNOTATIONS',
  },
  eraseAll: {
    id: 'ERASE_ALL',
    iconName: 'delete',
    name: 'eraseAll',
    isMouseAdaptor: true,
    description: 'Erase All',
    category: 'ANNOTATIONS',
  },
  vertebraeLabel: {
    id: 'VERTEBRAE_LABEL',
    iconName: 'vertical_split',
    name: 'vertebraeLabel',
    isMouseAdaptor: true,
    description: 'Label Vertebrae',
    category: 'ANNOTATIONS',
  },
  landmark: {
    id: 'LANDMARK',
    iconName: 'landmark',
    name: 'landmark',
    isMouseAdaptor: true,
    description: 'Landmark',
    category: 'ANNOTATIONS',
  },
  fastScroll: {
    id: 'FAST_SCROLL',
    iconName: 'layers',
    name: 'fastScroll',
    isMouseAdaptor: true,
    isRightMouseButtonAllowed: true,
    description: 'Fast Scroll',
    category: 'IMAGE_NAVIGATION',
  },
  regularScroll: {
    id: 'REGULAR_SCROLL',
    iconName: 'regular_scroll',
    name: 'regularScroll',
    isMouseAdaptor: true,
    isRightMouseButtonAllowed: true,
    description: 'Regular Scroll',
    category: 'IMAGE_NAVIGATION',
  },
  contour: {
    id: 'CONTOUR',
    iconName: 'border_color',
    name: 'contour',
    isMouseAdaptor: true,
    description: 'Contour',
    category: 'ANNOTATIONS',
  },
  toggleAnnotations: {
    id: 'TOGGLE_ANNOTATIONS',
    iconName: 'font_download',
    name: 'toggleAnnotations',
    isMouseAdaptor: false,
    description: 'Toggle Annotations',
    category: 'ANNOTATIONS',
  },
  jumpTo: {
    id: 'JUMP_TO',
    iconName: 'location_searching',
    name: 'jumpTo',
    isMouseAdaptor: true,
    description: 'Jump To',
    category: 'IMAGE_NAVIGATION',
  },
  reset: {
    id: 'RESET',
    iconName: 'reset',
    name: 'reset',
    isMouseAdaptor: false,
    description: 'Reset',
    category: 'IMAGE_ADJUSTMENT',
  },
  next_series_set: {
    id: 'NEXT_SET',
    iconName: 'skip_next',
    name: 'next_series_set',
    isMouseAdaptor: false,
    description: 'Next Series',
    category: 'IMAGE_ADJUSTMENT',
  },
  prev_series_set: {
    id: 'PREV_SET',
    iconName: 'skip_previous',
    name: 'prev_series_set',
    isMouseAdaptor: false,
    description: 'Previous Series',
    category: 'IMAGE_ADJUSTMENT',
  },
  keyImage: {
    id: 'KEY_IMAGE',
    iconName: 'key_image',
    name: 'keyImage',
    isMouseAdaptor: true,
    description: 'Bookmark',
    splitFlag: FF.IMAGE_BOOKMARK,
    category: 'ANNOTATIONS',
  },
  swapCells: {
    id: 'SWAP_CELLS',
    iconName: 'swap_calls',
    name: 'swapCells',
    isMouseAdaptor: true,
    description: 'Swap Viewports',
    category: 'IMAGE_NAVIGATION',
    keys: [{ key: 'shift' }],
    lockedKey: true,
    restrictions: [TOOL_RESTRICTIONS.shortcut_only],
  },
  switchViewType: {
    id: 'SWITCH_VIEWTYPE',
    iconName: 'switch_viewtype',
    name: 'switchViewType',
    isMouseAdaptor: false,
    description: 'View 2D/3D',
    category: 'IMAGE_ADJUSTMENT',
  },
  fastRuler: {
    id: 'FAST_RULER',
    iconName: 'fast_ruler',
    name: 'fastRuler',
    isMouseAdaptor: true,
    description: 'Fast Ruler',
    category: 'ANNOTATIONS',
    splitFlag: FF.FAST_ANNOTATIONS,
  },
  fastRoi: {
    id: 'FAST_ROI',
    iconName: 'fast_roi',
    name: 'fastRoi',
    isMouseAdaptor: true,
    description: 'Fast ROI',
    category: 'ANNOTATIONS',
    splitFlag: FF.FAST_ANNOTATIONS,
  },

  toggleThumbnailBar: {
    id: 'TOGGLE_THUMBNAIL_BAR',
    iconName: 'toggle_thumbnail_bar',
    name: 'toggleThumbnailBar',
    isMouseAdaptor: false,
    hideFromToolbar: false,
    description: 'Toggle Visibility of Study Thumbnail Bar',
    category: 'WORKFLOW',
  },
  toggleToolBar: {
    id: 'TOGGLE_TOOL_BAR',
    iconName: 'toggle_tool_bar',
    name: 'toggleToolBar',
    isMouseAdaptor: false,
    hideFromToolbar: false,
    description: 'Toggle Visibility of Sidenav',
    category: 'WORKFLOW',
  },
};

export const visibilityTools: { [key: string]: Tool } = {
  showAllVisibility: {
    id: 'SHOW_ALL_VISIBILITY',
    name: 'showAllVisibility',
    description: 'Show All',
    keys: [{ key: 'shift+a' }],
    isMouseAdaptor: false,
    iconName: 'visibility',
    category: 'ANNOTATIONS',
  },
  hideAllVisibility: {
    id: 'HIDE_ALL_VISIBILITY',
    name: 'hideAllVisibility',
    description: 'Hide All',
    keys: [{ key: 'shift+h' }],
    isMouseAdaptor: false,
    iconName: 'visibility',
    category: 'ANNOTATIONS',
  },
  toggleAnnotationsVisibility: {
    id: 'TOGGLE_ANNOTATIONS_VISIBILITY',
    name: 'toggleAnnotationsVisibility',
    description: 'Toggle Annotations Visibility',
    keys: [{ key: 'shift+v' }],
    isMouseAdaptor: false,
    iconName: 'visibility',
    category: 'ANNOTATIONS',
  },
  toggleMetadataVisibility: {
    id: 'TOGGLE_METADATA_VISIBILITY',
    name: 'toggleMetadataVisibility',
    description: 'Toggle Metadata',
    keys: [{ key: 'shift+m' }],
    isMouseAdaptor: false,
    iconName: 'visibility',
    category: 'ANNOTATIONS',
  },
  toggleMaskVisibility: {
    id: 'TOGGLE_MASK_VISIBILITY',
    name: 'toggleMaskVisibility',
    description: 'Toggle Masks',
    keys: [{ key: 'shift+s' }],
    isMouseAdaptor: false,
    iconName: 'visibility',
    category: 'ANNOTATIONS',
  },
  toggleGspsAnnotationsVisibility: {
    id: 'TOGGLE_GSPS_ANNOTATIONS_VISIBILITY',
    name: 'toggleGspsAnnotationsVisibility',
    description: 'Toggle GSPS Annotations',
    keys: [],
    isMouseAdaptor: false,
    iconName: 'visibility',
    category: 'ANNOTATIONS',
  },
};

export const scrollLinkTools: { [key: string]: Tool } = {
  automaticLinkedScroll: {
    id: 'AUTOMATIC_LINKED_SCROLL',
    iconName: 'automaticLinkedScroll',
    name: 'automaticLinkedScroll',
    isMouseAdaptor: false,
    isScrollAdaptor: true,
    description: 'Automatic Scroll',
    category: 'IMAGE_NAVIGATION',
  },
  manualLinkedScroll: {
    id: 'MANUAL_LINKED_SCROLL',
    iconName: 'manualLinkedScroll',
    name: 'manualLinkedScroll',
    isMouseAdaptor: false,
    isScrollAdaptor: true,
    description: 'Manual Scroll',
    category: 'IMAGE_NAVIGATION',
  },
};

export const allBuiltInTools = {
  ...builtInTools,
  ...visibilityTools,
  ...scrollLinkTools,
};

export const configurableTools = {
  ...builtInTools,
  ...scrollLinkTools,
};

export const getAllTools: () => Promise<Array<Tool>> = memoize(
  async () => {
    const builtInTools = Object.values(allBuiltInTools);
    try {
      const {
        data: { tools: allSdkTools },
      } = await apolloClient.query<GetToolsQuery, empty>({ query: GET_TOOLS });

      const sdkTools: Tool[] = allSdkTools.map((tool) => ({
        id: tool.id,
        name: tool.name,
        iconName: tool.name,
        isMouseAdaptor: tool.interactions.some(getSdkToolIsMouseAdaptor),
        description: tool.name,
        svgIcon: tool.icon,
        source: 'sdk',
        keys: getSdkToolKeys(tool.id, tool.interactions),
        category: tool.category,
      }));

      return [...builtInTools, ...sdkTools];
    } catch (e) {
      return builtInTools;
    }
  },
  () => 'allTools'
);

const getSdkToolIsMouseAdaptor = (interaction: $ReadOnly<ToolInteractionUnion>): boolean =>
  interaction.type === ToolInteractionTypeValues.ViewportClick;

const getSdkToolKeys = (
  toolId: string,
  interactions: $ReadOnlyArray<$ReadOnly<ToolInteractionUnion>>
): Tool['keys'] =>
  interactions.reduce((acc, i) => {
    if (i.type === ToolInteractionTypeValues.KeyboardShortcut && i.keyboardShortcut != null) {
      acc.push({
        key: i.keyboardShortcut,
        interactionId: i.id,
      });
    }
    return acc;
  }, []);

export const getToolById = async (id: string): Promise<?Tool> =>
  (await getAllTools()).find((x) => x.id === id);

export function useAllTools(): ?Array<Tool> {
  const { value, loading } = useAsync(async () => {
    return await getAllTools();
  }, []);

  return loading ? [] : value;
}

export function useConfigurableTools(): Array<Tool> {
  const tools = useAllTools();
  if (!tools) return [];
  return tools.filter((tool) => tool.source === 'sdk' || configurableTools[tool.name]);
}

export function useVisibilityTools(): ?Array<Tool> {
  const tools = useAllTools();
  if (!tools) return [];
  return tools.filter((tool) => visibilityTools[tool.name]);
}
