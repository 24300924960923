// @flow

import { useSearchParams } from 'react-router-dom';

export const usePriorStudiesParams = (): ?string => {
  const [searchParams] = useSearchParams();

  return searchParams.get('prior_studies');
};

export const getPriorsQueryParametersSuffix = (priorStudiesList: ?string): string => {
  return priorStudiesList != null && priorStudiesList.length > 0
    ? `?prior_studies=${priorStudiesList}`
    : '';
};
