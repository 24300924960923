// @flow
/**
 * Removes the "__typename" property from the given data object or array recursively.
 *
 * @param {Object|Array} data - The data object or array to remove "__typename" property from.
 * @returns {Object|Array} - The data object or array with "__typename" property removed.
 */
// $FlowIgnore[unclear-type] data is meant to be a response object so it could come in any shape
export const removeTypeName = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map((item) => {
      return removeTypeName(item);
    });
  } else if (typeof data === 'object') {
    const newObj = {};
    Object.keys(data).forEach((key) => {
      if (key !== '__typename') {
        newObj[key] = removeTypeName(data[key]);
      }
    });
    return newObj;
  }
  return data;
};
