// @flow
import Mousetrap from 'mousetrap';
import { useEffect } from 'react';

/* View in fullscreen */
function openFullscreen() {
  document.documentElement?.requestFullscreen?.();
}

export default function initMousetraps() {
  Mousetrap.bind('shift+f', openFullscreen);
}

export const useMousetrap = () => {
  useEffect(() => {
    initMousetraps();
  }, []);
};
