// @flow
import barcode from 'assets/UDI_barcode.svg';
import { Stack } from 'common/ui/Layout';
import Colors from 'styles/colors';
import Spacing from 'styles/spacings';

export const Barcode = (): React$Node => {
  return (
    <Stack>
      <img
        src={barcode}
        style={{
          width: '100%',
          padding: Spacing.large,
          backgroundColor: Colors.white,
          borderRadius: '4px',
        }}
        alt="UDI Barcode"
      />
    </Stack>
  );
};
