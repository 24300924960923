// @flow

import type { SplitClient } from '@splitsoftware/splitio';
import { useAsyncResource } from 'use-async-resource';
import { useCurrentUser } from 'hooks/useCurrentUser/suspense';
import { getClientWithState } from '../StatefulSplitClient';
import type { StatefulSplitClientState } from '../StatefulSplitClient';
import { logger } from 'modules/logger';

async function initializeSplitClient(email: string): Promise<SplitClient | null> {
  return new Promise((resolve, reject) => {
    const statefulClient = getClientWithState(email);
    if (statefulClient.state === 'ready') {
      logger.info('Suspense Split Client: already ready');
      resolve(statefulClient.splitClient);
    } else if (statefulClient.state === 'timeout') {
      logger.info('Suspense Split Client: already timed out');

      resolve(null);
    } else {
      logger.info('Suspense Split Client: pending');

      const onStateChange = (state: StatefulSplitClientState) => {
        if (state === 'ready') {
          logger.info('Suspense Split Client: ready');
          resolve(statefulClient.splitClient);
        } else if (state === 'timeout') {
          logger.info('Suspense Split Client: timed out');
          /**
           * Instead of rejecting the promise on timeout, we resolve with the
           * client as null to trigger a fallback value for the suspense version.
           */
          resolve(null);
        }
      };

      statefulClient.onStateChange(onStateChange);
    }
  });
}

/**
 * NOTE: uses Suspense, make sure to handle it accordingly
 */
export const useSplitClient = (): SplitClient | null => {
  const {
    me: { email },
  } = useCurrentUser();

  const [clientReader] = useAsyncResource(initializeSplitClient, email);

  const splitIoClient = clientReader();
  return splitIoClient;
};
