// @flow

import type { SplitFactoryConfig } from '@splitsoftware/splitio';
import { env } from './env';

/**
 * Validate that the dynamically imported module has a default export which is an object.
 */
export function isESModule(module: mixed): boolean {
  return typeof module === 'object' && module !== null && 'default' in module;
}

/**
 * Dynamically import local split file as JSON.
 * NOTE: ONLY USE WHEN DEPLOY ENV IS 'local' because we only inject the vite yaml plugin in local.
 */
export function importLocalSplits(): SplitFactoryConfig['features'] | void {
  if (env.LOCAL_SPLITS === 'true') {
    const path = '../../../.config/local.split.yaml';

    // $FlowIssue[not-a-function] Flow types `import.meta` as `mixed` and doesn't allow to set our own types for it
    const localSplitYaml = import.meta.glob('../../../.config/local.split.yaml', {
      eager: true,
    });

    if (!localSplitYaml || !localSplitYaml[path]) {
      throw new Error(
        `Could not find local split file "${path}", did you forget to add it to the project? You can run "yarn run -T cp-local-feature-flags" to generate it.`
      );
    }

    const localSplitYamlModule = localSplitYaml[path];

    // This is a mild validation on the imported module
    if (!isESModule(localSplitYamlModule)) throw new Error(`"${path}" is not an ES module`);

    const features: Array<{
      [key: string]: { treatment: string, keys?: string[], config?: string },
    }> = localSplitYamlModule.default;

    // we expect `features` to be a object.
    if (!features || typeof features !== 'object') {
      throw new Error(`Invalid content: ${path}\n${JSON.stringify(module, null, 2)}`);
    }

    let result = features.reduce<$NonMaybeType<SplitFactoryConfig['features']>>((acc, feature) => {
      const [key, config] = Object.entries(feature)[0];
      acc[key] = config;

      return acc;
    }, {});

    try {
      const localStorageFeatures = JSON.parse(
        window.localStorage.getItem('__SIRONA_SPLIT_MOCKS__') ?? '{}'
      );
      result = { ...result, ...localStorageFeatures };
    } catch (e) {
      console.error('Error parsing localStorage features:', e);
    }

    return result;
  } else {
    return undefined;
  }
}
