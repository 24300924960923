// @flow
import { useMemo } from 'react';
import analytics from 'modules/analytics';
import { globalContext } from 'modules/analytics/constants';
import { useSplitClient } from 'modules/feature-flags';
import { env } from 'config/env';
import { FFDefaults } from './featureFlags.generated';
import type { FeatureFlag } from './featureFlags.generated';

export function useSplitFlag<T = string>(
  flagName: FeatureFlag,
  fallback?: string,
  castTo?: (string) => T
): [T, boolean] {
  const [client, ready, timeout] = useSplitClient();
  const fallbackValue = fallback != null ? fallback : FFDefaults[flagName];
  const loading = ready || env.STORYBOOK === 'true' ? false : !timeout;
  const flag = useMemo(() => {
    if (client == null && env.STORYBOOK === 'true') {
      return fallbackValue;
    }
    if (timeout || (ready && client == null)) {
      analytics.addContext(globalContext.workspace.featureFlag(flagName), fallbackValue);
      return fallbackValue;
    }
    if (ready && client != null) {
      let value = client.getTreatment(flagName);
      if (value === 'control') {
        value = fallbackValue;
      }
      analytics.addContext(globalContext.workspace.featureFlag(flagName), value);
      return value;
    }
    return fallbackValue;
  }, [client, flagName, ready, fallbackValue, timeout]);
  // $FlowIgnore[incompatible-return]
  return [castTo ? castTo(flag) : flag, loading];
}

export const useSplitFlags = (flagNames: FeatureFlag[]): [?{ [x: string]: string }, boolean] => {
  const [client, ready, timeout] = useSplitClient();
  const loading = ready ? false : !timeout;
  const flags = useMemo(() => {
    if (ready) {
      // $FlowIgnore[unclear-type]
      const values = client?.getTreatments(((flagNames: any): string[]));
      if (values != null) {
        Object.keys(values).forEach((flag) => {
          analytics.addContext(globalContext.workspace.featureFlag(flag), values[flag]);
        });
      }
      return values;
    }
    return null;
  }, [client, flagNames, ready]);
  return [flags, loading];
};
export const withSplitFlags: (
  flagNames: FeatureFlag[],
  prop: string
) => (
  Component: React$AbstractComponent<{ [p: string]: { loading: boolean, flags: mixed } }, mixed>
) => (props: {}) => React$Element<mixed> =
  (flagNames, prop = 'treatments') =>
  (Component) =>
    function WithSplitFlags(props) {
      const [flags, loading] = useSplitFlags(flagNames);
      return <Component {...props} {...{ [prop]: { loading, flags } }} />;
    };
