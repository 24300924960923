// @flow

export const legacyColors = {
  mainBlue: '#308BFD',
  mainBlueHover: '#308BFDCC',
  backgroundBlue: '#041420',
  mainBlue6Percent: '#308BFD96',
  mainBlue36Percent: '#308BFD5c',
  white: '#ffffff',
  white80Percent: '#ffffffCC',
  white60Percent: '#ffffff66',
  white32Percent: '#ffffff51',
  toolbarButtonNormal: '#1a2a37',
  toolbarSelectedSVGStroke: '#ffffff',
  toolbarSelectedSVGFill: '#ffffff19',
  toolbarDropDownBackground: '#131F29',
  toolbarDropDownSelectedSVGStroke: '#308BFD',
  toolbarDropDownSelectedSVGFill: '#308BFD29',
  toolbarDropDownNormalSVGStroke: '#ffffff19',
  toolbarDropDownNormalSVGFill: 'transparent',
  whiteDisable: '#FFFFFF2C',
  hypothesisText: '#ffffff44',
  line: '#97979730',
  dialogBackground: '#131F29',
  macroHeader: '#172E46',
  macroDialogBackground: '#172d45',
  dialogInputBackground: '#131f29',
  buttonGrey: '#0b1218',
  buttonGreyHover: '#172d45',
  badge: '#ff4747',
  titleAICard: '#b413df',
  accountInputBackground: '#131f29',
  toolbar: '#0e202e',
  alert: '#fd7878',
};

export const baseColors = {
  gray0: '#000C14',
  gray1: '#041420',
  gray2: '#0e202e',
  gray3: '#1a2a37',
  gray4: '#2c3c47',
  gray5: '#42505a',
  gray6: '#5d6a72',
  gray7: '#859096',
  gray8: '#a5adb1',
  gray9: '#cdd2d3',
  gray10: '#ececec',
  gray11: '#ffffff21',

  blue1: '#0a4082',
  blue2: '#145bb4',
  blue3: '#2375db',
  blue4: '#4d94ec',
  blue5: '#7bb1f5',
  blue6: '#aec2d9',

  pink1: '#721a78',
  pink2: '#903397',
  pink3: '#b054b7',
  pink4: '#c56ecb',
  pink5: '#e69cec',
  pink6: '#fbc6ff',

  teal1: '#073642',
  teal2: '#10505f',
  teal3: '#1b6e82',
  teal4: '#409ba7',
  teal5: '#8bd3dd',
  teal6: '#b4e6e9',

  orange1: '#9a3c02',
  orange2: '#d36a0f',
  orange3: '#ef8c10',
  orange4: '#f4a62d',
  orange5: '#f6b651',
  orange6: '#f8cb82',

  purple1: '#2f084b',
  purple2: '#523661',
  purple3: '#6b4e7a',
  purple4: '#9172a1',
  purple5: '#b18dc5',
  purple6: '#d4bde0',

  red1: '#7d0505',
  red2: '#a22b2b',
  red3: '#bc4343',
  red4: '#ca5a5a',
  red5: '#d27272',
  red6: '#f69393',

  green1: '#042f23',
  green2: '#105240',
  green3: '#4d745c',
  green4: '#5fb480',
  green5: '#7eb191',
  green6: '#c3eed4',

  yellow1: '#be9a02',
  yellow2: '#cda910',
  yellow3: '#ecc621',
  yellow4: '#e5c84a',
  yellow5: '#edd670',
  yellow6: '#fff7c9',

  black: '#000',

  aiGreen: '#8feeb4',
};

export const appColors = {
  study0: baseColors.blue3,
  study1: baseColors.pink3,
  study2: baseColors.orange3,
  study3: baseColors.teal3,
  study4: baseColors.purple3,
  study5: '#9e0225',
  study6: '#9f6b2d',
  study7: '#2c7423',
  study8: '#c93e36',
  study9: baseColors.yellow3,
  study10: baseColors.gray4,

  // These colors are used when a study is highlighted and/or selected
  activeStudy0: baseColors.blue5,
  activeStudy1: baseColors.pink5,
  activeStudy2: baseColors.orange5,
  activeStudy3: baseColors.teal5,
  activeStudy4: baseColors.purple5,
  activeStudy5: '#de8d9c',
  activeStudy6: '#dfc5a0',
  activeStudy7: '#a0cc9d',
  activeStudy8: '#eaa8a1',
  activeStudy9: baseColors.yellow5,
  activeStudy10: baseColors.gray5,

  // Annotations
  ugcAnnotation: '#FFFF66',
  ugcAnnotationHover: '#99ff66',

  // presentation state annotations
  psAnnotation: '#E54A4A',
  psAnnotationHover: '#E58B4A',
};

const colors = {
  ...legacyColors,
  ...baseColors,
  ...appColors,
};

export type ColorsType = $Keys<typeof colors>;

export type ColorsValueType = $Values<typeof colors>;

export default colors;
