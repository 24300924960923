// @flow
import { withStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/InputBase';

import styles from './styles';
import type { Style } from 'styles';

import { NOOP } from 'config/constants';

type SettingsDropDownProps = {
  multiple?: boolean,
  classes: Style,
  children?: React$Node,
  value?: string | number | string[] | $ReadOnlyArray<string>,
  onSelectedChange?: (v: string | number | string[] | $ReadOnlyArray<string>) => mixed,
  onClose?: (v: string | number | string[]) => mixed,
  menuId?: string,
  disabled?: boolean,
  displayEmpty?: boolean,
  'data-testid'?: string,
};

function SettingsDropDownComponent({
  classes,
  children = [],
  value,
  onSelectedChange = NOOP,
  onClose = NOOP,
  menuId = '',
  disabled = false,
  multiple = false,
  'data-testid': dataTestId,
  displayEmpty = false,
}: SettingsDropDownProps): React$Node {
  const handleOnChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    onSelectedChange(event.target.value);
  };

  const defaultValue = multiple ? [] : undefined;

  return (
    <Select
      disabled={disabled}
      className={classes.root}
      multiple={multiple}
      MenuProps={{
        id: menuId,
        'data-testid': menuId,
        // Clearing `getContentAnchorEl` is required to anchor the container of generated MenuItems
        // to the parent element; otherwise the container will anchor itself arbitrarily on every
        // render. More info: https://github.com/mui/material-ui/issues/7961#issuecomment-326116559
        getContentAnchorEl: null,
        BackdropProps: {
          id: `${menuId}-backdrop`,
        },
        classes: {
          paper: classes.settingDropDownMenuPaper,
        },
      }}
      renderValue={
        // Allow settings dropdown to compute the value based on children when not in multiple
        multiple
          ? (value) => {
              if (Array.isArray(value)) {
                return value.join(', ');
              }
            }
          : undefined
      }
      input={<Input classes={{ input: classes.settingDropDownInput }} />}
      value={value ?? defaultValue}
      displayEmpty={displayEmpty}
      onChange={handleOnChange}
      onClose={onClose}
      data-testid={dataTestId}
    >
      {children}
    </Select>
  );
}

const SettingsDropdown: React$AbstractComponent<
  $Rest<SettingsDropDownProps, { classes: Style }>,
  mixed,
> = withStyles(styles)(SettingsDropDownComponent);

export default SettingsDropdown;
