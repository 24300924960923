// @flow
import { LOADING_SCREEN_TIMEOUT } from 'config/constants';
import { useEffect, useLayoutEffect, useRef, useState, createContext } from 'react';
import useAuth from 'hooks/useAuth';

// $FlowFixMe[untyped-import] (automated-migration-2022-01-19)
import { useSessionStorage } from 'hooks/useStorage';

export type LoadingScreenResult = {
  showLoadingScreen: boolean,
  didShowLoadingScreen: boolean,
};

export const LoadingScreenContext: React$Context<LoadingScreenResult> =
  createContext<LoadingScreenResult>({
    showLoadingScreen: false,
    didShowLoadingScreen: false,
  });

export const useLoadingScreen = (): LoadingScreenResult => {
  const { isAuthenticated } = useAuth();
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [didShowLoadingScreen, setDidShowLoadingScreen] = useState(false);
  const [loadingScreen, setLoadingScreen] = useSessionStorage('loading-screen', null);
  const timeoutRef = useRef();

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (loadingScreen == null) {
      setLoadingScreen(true);
      setShowLoadingScreen(true);
      setDidShowLoadingScreen(true);

      timeoutRef.current = setTimeout(() => {
        setShowLoadingScreen(false);
      }, LOADING_SCREEN_TIMEOUT);
    } else {
      return () => {
        clearTimeout(timeoutRef.current);
        setShowLoadingScreen(false);
        setDidShowLoadingScreen(true);
        setLoadingScreen(null);
      };
    }
  }, [isAuthenticated, loadingScreen, setLoadingScreen, setDidShowLoadingScreen]);

  return {
    showLoadingScreen,
    didShowLoadingScreen,
  };
};

export const usePostLoadState = (enabled: boolean = true): boolean => {
  const [loadingScreen] = useSessionStorage('loading-screen', null);
  const [postLoadState, setPostLoadState] = useState(loadingScreen != null);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!enabled) return;

    let timeout;

    if (isAuthenticated) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setPostLoadState(true);
      }, LOADING_SCREEN_TIMEOUT);
    } else {
      setPostLoadState(false);
      clearTimeout(timeout);
    }
  }, [isAuthenticated, enabled]);

  return enabled ? postLoadState : true;
};
