// @flow
import { useCallback } from 'react';
import Text from 'common/ui/Text';
import { IconButton, Switch } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useMutation } from '@apollo/client';
import { Stack, Spacer } from 'common/ui/Layout';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { SettingsCard, SettingsCardContent, SettingsCardHeader } from './SettingsLayoutComponents';
import { GET_ME, UPDATE_WORKLIST_SETTINGS } from 'modules/Apollo/queries';
import { MenuSeparator } from '../ui/MenuSeparator/index';
import { assocPath } from 'ramda';

type WorklistSettingsTabProps = {
  onClose: () => void,
  setError: (error: ?string) => void,
};

export const WorklistSettingsTab = ({
  onClose,
  setError,
}: WorklistSettingsTabProps): React$Node => {
  const [updateWorklistSettings] = useMutation(UPDATE_WORKLIST_SETTINGS);
  const { data } = useCurrentUser();
  const worklistSettings = data?.me.worklistSettings;

  const openPatientJacketOnRead = worklistSettings?.openPatientJacketOnRead ?? true;

  const toggleOpenPatientJacketOnRead = useCallback(
    async (evt: empty) => {
      const updatedResponse = {
        ...worklistSettings,
        openPatientJacketOnRead: !openPatientJacketOnRead,
      };

      await updateWorklistSettings({
        variables: { openPatientJacketOnRead: !openPatientJacketOnRead },
        optimisticResponse: {
          __typename: 'Mutation',
          updateWorklistSettings: {
            __typename: 'WorklistSettings',
            ...updatedResponse,
          },
        },
        update: (proxy) => {
          proxy.writeQuery({
            query: GET_ME,
            data: assocPath(
              ['me', 'worklistSettings', 'openPatientJacketOnRead'],
              !openPatientJacketOnRead,
              data
            ),
          });
        },
      });
    },
    [data, openPatientJacketOnRead, updateWorklistSettings, worklistSettings]
  );

  return (
    <SettingsCard>
      <SettingsCardHeader>
        <Stack alignY="center">
          <Text variant="display2">Worklist Settings</Text>
          <Spacer />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </SettingsCardHeader>
      <SettingsCardContent>
        <Stack vertical space="medium">
          <Stack vertical space="small">
            <Text variant="display1" display="block">
              General
            </Text>
            <Stack alignY="center">
              <Text>Automatically open patient jacket when opening a case</Text>
              <Spacer />
              <Switch
                data-testid="open-patient-jacket-switch"
                checked={openPatientJacketOnRead}
                onChange={toggleOpenPatientJacketOnRead}
              />
            </Stack>
            <MenuSeparator />
          </Stack>
        </Stack>
      </SettingsCardContent>
    </SettingsCard>
  );
};
