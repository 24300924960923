// @flow
import type { PopupOptions } from 'utils/openPopup';
import { openPopup } from 'utils/openPopup';

export const openTab = (url: string, popupOptions?: PopupOptions): void => {
  const isRunningAsStandaloneApp = window.matchMedia('(display-mode: standalone)').matches;

  // If the app is running as a standalone app we don't want to open the worklist in a
  // normal tab but rather in a popup, that will look like yet another standalone window
  if (isRunningAsStandaloneApp) {
    openPopup(url, popupOptions);
  } else {
    window.open(url, '_blank');
  }
};
