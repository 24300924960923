// @flow

import { globalContext } from './modules/analytics/constants';
import analytics from 'modules/analytics';
import { useInterval } from 'react-use';

// avoiding use of a name like `DatadogSharedContextProvider` to avoid
// being confused with React.Context
export function DatadogSharedContextApplier(): React$Node {
  useQuotaMonitoring();

  return null;
}

const QUOTA_MONITORING_INTERVAL_MS = 15000;

function useQuotaMonitoring() {
  monitorQuota();
  useInterval(monitorQuota, QUOTA_MONITORING_INTERVAL_MS);
}

async function monitorQuota() {
  // $FlowIgnore[prop-missing] Flow's navigator typing is incomplete
  const storageEstimate = await navigator?.storage.estimate();
  if (storageEstimate == null) {
    return;
  }

  analytics.addContext(globalContext.workspace.storageEstimate, {
    ...storageEstimate,
    percentUsed: storageEstimate.quota === 0 ? 1 : storageEstimate.usage / storageEstimate.quota,
  });
}
