// @flow

import useResizeAware from 'react-resize-aware';
import { useEffect, useState, useMemo, useContext, useCallback, createContext } from 'react';

// Aim is to support minimum dimensions of 960px x 540px, so default props of 1184px x 666px afford some leeway
const DEFAULT_COMPACT_HEIGHT = 666;
const DEFAULT_COMPACT_WIDTH = 1184;

type CompactUIContextProps = $ReadOnly<{
  children: React$Node,
  compactHeight?: number,
  compactWidth?: number,
}>;

export type CompactUIContextBag = $ReadOnly<{
  showCompactUI: boolean,
  showCompactHeight: boolean,
  showCompactWidth: boolean,
  resizeListener: React$Node,
  sizes: ?{ width: number, height: number },
}>;

const CompactUIContext = createContext<?CompactUIContextBag>(undefined);

export const CompactUIProvider = ({
  children,
  compactHeight = DEFAULT_COMPACT_HEIGHT,
  compactWidth = DEFAULT_COMPACT_WIDTH,
}: CompactUIContextProps): React$Node => {
  const isCompactWidth = useCallback((width: number) => width <= compactWidth, [compactWidth]);
  const isCompactHeight = useCallback((height: number) => height <= compactHeight, [compactHeight]);

  const [resizeListener, sizes] = useResizeAware();
  const [showCompactUI, setShowCompactUI] = useState(
    sizes?.height != null ? isCompactHeight(sizes.height) || isCompactWidth(sizes.height) : false
  );
  const [showCompactHeight, setShowCompactHeight] = useState(
    sizes?.height != null ? isCompactHeight(sizes.height) : false
  );
  const [showCompactWidth, setShowCompactWidth] = useState(
    sizes?.width != null ? isCompactWidth(sizes.width) : false
  );

  useEffect(() => {
    const height = sizes?.height;
    const width = sizes?.width;
    if (height != null && width != null) {
      setShowCompactUI(isCompactHeight(height) || isCompactWidth(width));
      setShowCompactHeight(isCompactHeight(height));
      setShowCompactWidth(isCompactWidth(width));
    }
  }, [sizes?.height, sizes?.width, compactWidth, compactHeight, isCompactHeight, isCompactWidth]);

  const compactUIBag = useMemo(
    () => ({
      resizeListener,
      showCompactUI,
      showCompactHeight,
      showCompactWidth,
      sizes,
    }),
    [resizeListener, showCompactUI, showCompactHeight, showCompactWidth, sizes]
  );

  return <CompactUIContext.Provider value={compactUIBag}>{children}</CompactUIContext.Provider>;
};

export const useCompactUI = (): CompactUIContextBag => {
  const compactUI = useContext(CompactUIContext);

  if (!compactUI) {
    throw new Error('useCompactUI must be within a CompactUIProvider.');
  }

  return compactUI;
};
