// @flow

import { useCallback, useEffect, useRef } from 'react';
import type { SystemCheckValidatorsMap } from './validators';
import { useDisplaySystemCheck, useSystemCheck } from './useSystemCheck';
import { SystemCheckModal } from './SystemCheckModal';
import { logger } from 'modules/logger';
import { SystemCheckValidators } from './validators';
import { NOOP } from 'config/constants';
import { useCurrentUser } from 'hooks/useCurrentUser';

/** Delay post-mount before the first validation */
export const SYS_CHECK_MOUNT_DELAY_MS = 1000;
/** Artifical wait after a recheck is triggered */
export const SYS_CHECK_VALIDATE_TIMEOUT_MS = 1000;

export type Props = {
  validators?: SystemCheckValidatorsMap,
  /** Callback for after the results are confirmed */
  onContinue?: () => void,
};
export const SystemCheck = ({
  onContinue = NOOP,
  validators = SystemCheckValidators,
}: Props): React$Node => {
  const {
    setBypassSystemCheck: setBypass,
    isSystemValid,
    reset,
    shouldBypassSystemCheck,
    validate,
    validationResults,
  } = useSystemCheck(validators);
  const { handleOnContinue, shouldShowSystemCheck, showSystemCheckScreen } =
    useDisplaySystemCheck();
  const initialSystemCheckTimeoutId = useRef<?TimeoutID>(null);
  const { data: user } = useCurrentUser();

  // This effect is responsible for executing the first system check after: 1) the getMe gql query
  // resolves and 2) the `SYS_CHECK_MOUNT_DELAY_MS` delay timeout is reached. This is to ensure that
  // the system check is not executed before the code injection event from the extension.
  useEffect(() => {
    const scheduleInitialSystemCheck = () => {
      // Delay the initial validation to allow the rest of the app to load, otherwise we'll evaluate
      // the system prior to the extension injecting the variables.

      // In the future, we should replace the timer-based check with an actual request & response
      // from the extension with a timeout.
      initialSystemCheckTimeoutId.current = setTimeout(() => {
        const result = validate();

        if (!result.isSystemValid && !shouldBypassSystemCheck) {
          showSystemCheckScreen();
        }
      }, SYS_CHECK_MOUNT_DELAY_MS);
    };
    if (user && !shouldBypassSystemCheck && initialSystemCheckTimeoutId.current == null) {
      scheduleInitialSystemCheck();
    }
  }, [user, shouldBypassSystemCheck, showSystemCheckScreen, validate, initialSystemCheckTimeoutId]);

  const handleContinue = useCallback(() => {
    if (shouldBypassSystemCheck && !isSystemValid) {
      logger.warn(`[SysCheck] User elected to bypass failed system check`);
    }
    handleOnContinue();
    onContinue();
  }, [isSystemValid, handleOnContinue, onContinue, shouldBypassSystemCheck]);

  const handleRecheck = useCallback(() => {
    reset();
    setTimeout(() => {
      validate();
    }, SYS_CHECK_VALIDATE_TIMEOUT_MS);
  }, [reset, validate]);

  return (
    <SystemCheckModal
      isOpen={shouldShowSystemCheck}
      isSystemValid={isSystemValid}
      shouldBypassSystemCheck={shouldBypassSystemCheck}
      validationResults={validationResults}
      setBypass={setBypass}
      setModalState={handleContinue}
      recheck={handleRecheck}
    />
  );
};

export default SystemCheck;
