// @flow
import { useMemo, useEffect, useRef, useCallback } from 'react';
import { GET_BASE_VIEWER_DATA } from 'modules/Apollo/queries';
import useStudyIds from 'hooks/useStudyIds';
import { useWorklistId } from 'hooks/useWorklistId';
import { useRegisterStudyColors } from 'hooks/useStudyColor';
import type { Study, StudyGroup } from 'domains/viewer/ViewportsConfigurations/types';
import type { GetBaseViewerDataQuery, GetBaseViewerDataQueryVariables } from 'generated/graphql';
import { seriesToSlimSeries, stackToSlimStack } from '../../ViewportsConfigurations/manipulators';
import type { ApolloQueryResult } from '@apollo/client';
import typeof { NetworkStatus } from '@apollo/client';
import { emitPrecacheStatus } from 'modules/Apollo/emitPrecacheStatus';
import { usePrecacheQuery } from 'modules/Apollo/usePrecacheQuery';
import { useIsViewerMetadataPrecacheEnabled } from './useIsViewerMetadataPrecacheEnabled';
import { deletePrecacheQuery } from 'modules/Apollo/precacheOperation';
import { logger } from 'modules/logger';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags/suspense';
import { useToasterDispatch } from 'common/ui/Toaster/Toaster';
import { atomFamily, useRecoilState } from 'recoil';
import type { PresentationState } from '../StudiesBar/PresentationStatesWidget';
import { useViewerId } from 'hooks/useViewerId';

const dispatchedPSErrorToast = atomFamily({
  key: 'dispatchedPSErrorToast',
  default: false,
});

export const useStudyColorTracker = ({
  loadedStudies,
  pendingStudies,
}: {
  loadedStudies: string[],
  pendingStudies: string[],
}): void => {
  const registerStudyColors = useRegisterStudyColors();

  useEffect(() => {
    registerStudyColors(loadedStudies, pendingStudies);
  }, [loadedStudies, pendingStudies, registerStudyColors]);
};

type BaseViewerDataHook = {
  studies: $ReadOnlyArray<Study>,
  mainStudiesIds: $ReadOnlyArray<string>,
  loading: boolean,
  notFound: boolean,
  smids?: Array<string>,
  refetch: () => Promise<ApolloQueryResult<GetBaseViewerDataQuery>>,
  hangingProtocol?: GetBaseViewerDataQuery['matchHangingProtocolByWorkListsAndStudies'],
  networkStatus: $Values<NetworkStatus>,
  presentationStates: $ReadOnlyArray<PresentationState>,
  hasPresentationStateError: boolean,
  invalidatePrecacheForCurrentStudy: () => Promise<void>,
};

export const useBaseViewerData = (
  { shouldEmitPrecacheStatus }: { shouldEmitPrecacheStatus: boolean } = {
    shouldEmitPrecacheStatus: false,
  }
): BaseViewerDataHook => {
  const studyIds = useStudyIds();
  const worklistId = useWorklistId();
  const emittedPrecacheStatus = useRef(false);
  const hasWorklistIds = worklistId != null;
  const queryVariables = useMemo(
    () => ({
      worklistIds: [worklistId].filter(Boolean),
      studyIds,
      hasWorklistIds,
      hasStudyIds: studyIds.length > 0,
    }),
    [studyIds, worklistId, hasWorklistIds]
  );

  const shouldRefetch = useFeatureFlagEnabled(FF.VIEWER_DATA_REFETCH);
  const precacheEnabled = useIsViewerMetadataPrecacheEnabled();

  const { loading, data, previousData, refetch, error, networkStatus } = usePrecacheQuery<
    GetBaseViewerDataQuery,
    GetBaseViewerDataQueryVariables,
  >(
    GET_BASE_VIEWER_DATA,
    {
      variables: queryVariables,
      skip: !hasWorklistIds,
      subscribeToNetworkChanges: true,
    },
    {
      shouldRefetch,
      precacheEnabled,
    }
  );

  // We use the previous data to avoid flickering when the query is loading
  let latestPayload = data;
  if (latestPayload == null && previousData?.workListItems != null) {
    const hasMatches = previousData?.workListItems?.items.some(
      (worklist) => worklist.smid === worklistId
    );

    if (hasMatches) {
      latestPayload = previousData;
    } else {
      emittedPrecacheStatus.current = false;
    }
  }

  useEffect(() => {
    if (!shouldEmitPrecacheStatus || emittedPrecacheStatus.current) return;
    emitPrecacheStatus(GET_BASE_VIEWER_DATA, queryVariables);
    emittedPrecacheStatus.current = true;
  }, [queryVariables, shouldEmitPrecacheStatus]);

  const mainStudiesIds = useMemo(
    () =>
      latestPayload?.workListItems?.items
        .flatMap((worklist) => worklist.studies)
        .map((study) => study.smid) ?? [],
    [latestPayload]
  );

  const studies = useMemo(() => {
    const mainStudies =
      latestPayload?.workListItems?.items.flatMap((worklist) => worklist.studies) ?? [];
    const comparativeStudies = latestPayload?.studiesBySmid ?? [];
    return [...mainStudies, ...comparativeStudies].filter(Boolean);
  }, [latestPayload]);

  const invalidatePrecacheForCurrentStudy = useCallback(async () => {
    logger.info('Deleting precache query GET_BASE_VIEWER_DATA with variables:', queryVariables);
    await deletePrecacheQuery({ query: GET_BASE_VIEWER_DATA, variables: queryVariables });
  }, [queryVariables]);

  // This is a failsafe mechanism to delete the cache if the query returns no studies
  // If no studies are returned it's VERY likely something in our cache is wrong and
  // it does no harm to delete it for this query since it's theoretically empty anyway
  useEffect(() => {
    if (!loading && studies.length === 0) {
      invalidatePrecacheForCurrentStudy();
    }
  }, [loading, studies.length, invalidatePrecacheForCurrentStudy]);

  const unableToFindStudyError =
    error?.graphQLErrors?.some((err) => err.extensions.code === 404) ?? false;

  const notFound = !loading && (unableToFindStudyError || studies.length === 0);

  const smids = useMemo(() => studies.map((study) => study.smid), [studies]);

  const hangingProtocol = latestPayload?.matchHangingProtocolByWorkListsAndStudies;

  // $FlowFixMe[prop-missing] our version of Flow does not support type refinements well
  const presentationStates: $ReadOnlyArray<PresentationState> = useMemo(
    () => latestPayload?.presentationStates.filter((ps) => 'smid' in ps) ?? [],
    [latestPayload]
  );

  const { enqueueToast } = useToasterDispatch();
  const [hasDispatchedPSErrorToast, setDispatchedPSErrorToast] = useRecoilState(
    dispatchedPSErrorToast(worklistId)
  );

  const hasPresentationStateError = useMemo(
    () => latestPayload?.presentationStates.find((ps) => 'message' in ps) != null,
    [latestPayload]
  );

  const isViewer = useViewerId() != null;

  useEffect(() => {
    if (!hasPresentationStateError || hasDispatchedPSErrorToast || !isViewer) return;

    setDispatchedPSErrorToast(true);
    enqueueToast(
      `Error fetching presentation states. Editing of presentation states has been disabled.`,
      {
        severity: 'error',
        isUnique: true,
      }
    );
  }, [
    hasPresentationStateError,
    enqueueToast,
    hasDispatchedPSErrorToast,
    setDispatchedPSErrorToast,
    isViewer,
  ]);

  return {
    smids,
    studies,
    mainStudiesIds,
    loading,
    notFound,
    refetch,
    hangingProtocol,
    networkStatus,
    presentationStates,
    hasPresentationStateError,
    invalidatePrecacheForCurrentStudy,
  };
};

export type StudiesHookResult = {
  groups: Array<Array<string>>,
  groupedStudies: Array<StudyGroup>,
  ...BaseViewerDataHook,
};
export const useStudies = (): StudiesHookResult => {
  const { smids = [], studies, mainStudiesIds, ...studiesHook } = useBaseViewerData();
  const groups: Array<Array<string>> = useMemo(() => {
    const currentCaseStudies = studies.filter((study) => mainStudiesIds.includes(study.smid));

    const primaryStudySmids = currentCaseStudies.map((study) => study.smid) ?? [];
    return [
      primaryStudySmids,
      ...smids.filter((smid) => !primaryStudySmids.includes(smid)).map((smid) => [smid]),
    ].filter((group) => group.length > 0);
  }, [mainStudiesIds, smids, studies]);

  const groupedStudies: Array<StudyGroup> = useMemo(
    () =>
      groups.map((group) =>
        group.reduce<StudyGroup>((acc, studySmid) => {
          const study = studies.find((study) => study.smid === studySmid);
          if (study == null) return acc;
          return [
            ...acc,
            {
              smid: study.smid,
              description: study.description,
              seriesList: study.seriesList.map((series) => seriesToSlimSeries(series)),
              stackedFrames: study.stackedFrames.map((stack) => stackToSlimStack(stack)),
            },
          ];
        }, [])
      ),
    [groups, studies]
  );

  return {
    groups,
    groupedStudies,
    studies,
    mainStudiesIds,
    ...studiesHook,
  };
};
