// @flow
import { useMutation } from '@apollo/client';
import type { EraseAllToolScope } from 'generated/graphql';
import { TOGGLE_ERASE_ALL_TOOL_SCOPE } from 'modules/Apollo/queries';
import { useCurrentUser } from './useCurrentUser';
import { GET_ME } from '../modules/Apollo/queries';
import { assocPath } from 'ramda';
import { notifyUserSettingsUpdate } from './useViewerSettingsSync';

/** Hook for fetching and setting the Erase All tool behavior */
export const useEraseAllToolScopeSetting = (): [
  EraseAllToolScope | null,
  (EraseAllToolScope) => Promise<void>,
] => {
  const { data, refetch: refetchUser } = useCurrentUser();
  const me = data?.me;
  const eraseAllToolScope = me?.viewerSettings?.eraseAllToolScope ?? null;

  const [saveEraseAllToolScopeValue] = useMutation(TOGGLE_ERASE_ALL_TOOL_SCOPE, {
    onCompleted: () => {
      notifyUserSettingsUpdate();
    },
    onError: () => {
      refetchUser();
    },
    update(cache, res, { variables }) {
      if (me == null) {
        return;
      }

      const { eraseAllToolScope } = variables || {
        eraseAllToolScope: null,
      };

      cache.writeQuery({
        query: GET_ME,
        data: assocPath(['me', 'viewerSettings', 'eraseAllToolScope'], eraseAllToolScope, data),
      });
    },
  });

  const toggleEraseAllToolScope = async (eraseAllToolScope: EraseAllToolScope) => {
    await saveEraseAllToolScopeValue({
      variables: {
        eraseAllToolScope,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        toggleEraseAllToolScope: {
          eraseAllToolScope,
          __typename: 'EraseAllToolScopeResponse',
        },
      },
    });
  };

  return [eraseAllToolScope, toggleEraseAllToolScope];
};
