// @flow

import { PATH } from 'config/constants';
import { useAuthContext } from 'hooks/useAuth';
import { isAuthlessPathname } from 'utils/router';

import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RouteElement = ({
  title,
  children,
}: {
  title?: string,
  children: React$Node,
}): React$Node => {
  const { isAuthenticated } = useAuthContext();
  const location = useLocation();

  useEffect(() => {
    document.title = title != null ? `${title} | SIRONA` : 'SIRONA';
  }, [title]);

  if (!isAuthenticated && !isAuthlessPathname(location.pathname)) {
    // Since we have a route for logout we don't want to use that one as 'previous' url.
    const state = ![PATH.LOGIN, PATH.LOGOUT].includes(location.pathname)
      ? { from: { pathname: location.pathname, search: location.search } }
      : undefined;
    return <Navigate to={PATH.LOGIN} state={state} />;
  }

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="out"
      css={`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        min-width: 0;
        flex: 1 1 auto;
      `}
    >
      {children}
    </motion.div>
  );
};

const pageVariants = {
  initial: {
    opacity: 0,
    pointerEvents: 'none',
  },
  in: {
    opacity: 1,
    pointerEvents: 'auto',
  },
  out: {
    opacity: 0,
    pointerEvents: 'none',
  },
};

export default RouteElement;
