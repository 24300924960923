// @flow
import { useState } from 'react';
import { Spacer, Stack } from '../ui/Layout';
import {
  SettingsCard,
  SettingsCardContent,
  SettingsCardHeader,
  SettingsSeparator,
} from './SettingsLayoutComponents';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Text from 'common/ui/Text';
import { useCurrentUser } from 'hooks/useCurrentUser';
import DownloadAuthenticator from './DownloadAuthenticator';
import MultiFactorAuthToggle from './MFAToggle';

export const SecuritySettingsTab = ({ onClose }: { onClose: () => void }): React$Node => {
  const { data: user } = useCurrentUser();
  const me = user?.me;
  const [success, setSuccess] = useState(null);
  const mfaEnabled = me?.securitySettings.multiFactorAuth.enabled ?? false;
  const mfaLinked = me?.securitySettings.multiFactorAuth.linked ?? false;
  const [authenticatorToken, setAuthenticatorToken] = useState(null);

  return (
    <SettingsCard>
      <SettingsCardHeader>
        <Stack alignY="center">
          <Text variant="display2">Login & Security</Text>
          <Spacer />
          <IconButton onClick={onClose} data-testid="security-tab-close">
            <Close />
          </IconButton>
        </Stack>
      </SettingsCardHeader>
      <SettingsCardContent>
        <Text variant="display1" display="block" gutterBottom="small">
          Multi-factor Authentication
        </Text>
        <MultiFactorAuthToggle
          multiFactorAuthEnabled={mfaEnabled}
          multiFactorAuthLinked={mfaLinked}
          setAuthenticatorToken={setAuthenticatorToken}
          authenticatorToken={authenticatorToken}
          setSuccess={setSuccess}
        />
        {success != null && (
          <span>
            <Text color="green4" data-testid="linked-success">
              {success}
            </Text>
          </span>
        )}
        {!mfaLinked && mfaEnabled && authenticatorToken && (
          <DownloadAuthenticator onSuccess={setSuccess} authenticatorToken={authenticatorToken} />
        )}
        <SettingsSeparator />
      </SettingsCardContent>
    </SettingsCard>
  );
};
