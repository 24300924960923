// @flow

import { FF, useFeatureFlagEnabled } from 'modules/feature-flags/suspense';

export function useIsViewerMetadataPrecacheEnabled(): boolean {
  const topWorklistPrefetch = useFeatureFlagEnabled(FF.PREFETCH_TOP_WORKLIST_ITEMS);
  const hoveredWorklistPrefetch = useFeatureFlagEnabled(FF.PREFETCH_HOVERED_WORKLIST_ITEM);
  const claimedWorklistPrefetch = useFeatureFlagEnabled(FF.PREFETCH_CLAIMED_WORKLIST_ITEMS);
  const readCasePrefetch = useFeatureFlagEnabled(FF.PREFETCH_READ_CASE_ITEM);

  return (
    topWorklistPrefetch || hoveredWorklistPrefetch || claimedWorklistPrefetch || readCasePrefetch
  );
}
