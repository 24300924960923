// @flow
import styled, { css } from 'styled-components';
import type { StyledComponent } from 'styled-components';
import { Colors, Spacings } from 'styles';

const alignments = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  baseline: 'baseline',
  between: 'space-between',
};

const shouldForwardProp = (prop: string, defaultValidatorFn: ?(prop: string) => boolean) =>
  (!['vertical', 'stretchY', 'stretchX', 'space', 'padding', 'alignX', 'alignY', 'wrap'].includes(
    prop
  ) &&
    defaultValidatorFn?.(prop)) ??
  true;

export const Spacer: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  flex: 1;
`;

type DividerProps = {
  vertical: boolean,
  ...
};

export const Divider: StyledComponent<DividerProps, { ... }, HTMLDivElement> = styled.div.attrs({
  vertical: false,
})`
  width: ${(props: DividerProps) => (props.vertical ? 'auto' : '1px')};
  height: ${(props: DividerProps) => (props.vertical ? '1px' : 'auto')};
  align-self: stretch;
  background-color: ${Colors.gray5};
`;

type StackProps = {
  children: ?React$Node,
  className: string,
  vertical: boolean,
  stretchY: boolean,
  stretchX: boolean,
  padding: $Keys<typeof Spacings>,
  space: $Keys<typeof Spacings>,
  alignX: $Keys<typeof alignments>,
  alignY: $Keys<typeof alignments>,
  wrap: boolean,
  ...
};

export const Stack: StyledComponent<$Partial<StackProps>, { ... }, HTMLDivElement> = styled.div
  .withConfig({
    shouldForwardProp,
  })
  .attrs(({ wrap, ...props }) => ({
    vertical: false,
    stretchY: false,
    stretchX: false,
    space: 'none',
    padding: 'none',
    alignX: 'stretch',
    alignY: 'stretch',
    ...props,
  }))`
  display: flex;
  flex-wrap: ${(props: StackProps) => (props.wrap ? 'wrap' : 'nowrap')};
  justify-content: ${(props: StackProps) => alignments[props.alignX]};
  align-items: ${(props: StackProps) => alignments[props.alignY]};
  height: ${(props: StackProps) => (props.stretchY ? '100%' : 'auto')};
  align-self: ${(props: StackProps) => (props.stretchX ? 'stretch' : 'initial')};
  padding: ${(props: StackProps) => Spacings[props.padding]}rem;

  > *:not(:last-child) {
    margin-right: ${(props: StackProps) => Spacings[props.space]}rem;
  }

  ${(props: StackProps) =>
    props.vertical &&
    css`
      flex-direction: column;
      justify-content: ${(props: StackProps) => alignments[props.alignY]};
      align-items: ${(props: StackProps) => alignments[props.alignX]};

      > *:not(:last-child) {
        margin-right: 0;
        margin-bottom: ${(props: StackProps) => Spacings[props.space]}rem;
      }
    `};

  [data-stack-stretch] {
    flex: 1;
  }

  > * {
    margin: 0;
  }
`;

type GridOptionsOptional = {
  className: string,
  padding: $Keys<typeof Spacings>,
  space: $Keys<typeof Spacings>,
  spaceX: $Keys<typeof Spacings>,
  spaceY: $Keys<typeof Spacings>,
  alignX: $Keys<typeof alignments>,
  alignY: $Keys<typeof alignments>,
};

type GridProps = {
  children: React$Node,
  columns: Array<string>,
  rows: Array<string>,
  ...$Partial<GridOptionsOptional>,
  ...
};

export const Grid: StyledComponent<GridProps, { ... }, HTMLDivElement> = styled.div
  .withConfig({
    shouldForwardProp,
  })
  .attrs((props) => ({
    space: 'none',
    padding: 'none',
    alignX: 'stretch',
    alignY: 'stretch',
    columns: [],
    rows: [],
    ...props,
  }))`
  display: grid;
  justify-content: ${(props: GridOptionsOptional) => alignments[props.alignX]};
  align-items: ${(props: GridOptionsOptional) => alignments[props.alignY]};
  grid-gap: ${(props: GridOptionsOptional) => Spacings[props.space]}rem;
  ${(props: GridOptionsOptional) =>
    props.spaceY != null ? `grid-row-gap: ${Spacings[props.spaceY]}rem;` : ''}
  ${(props: GridOptionsOptional) =>
    props.spaceX != null ? `grid-column-gap: ${Spacings[props.spaceX]}rem;` : ''}
  grid-template-columns: ${(props: GridProps) => props.columns.join(',')};
  grid-template-rows: ${(props: GridProps) => props.rows.join(',')};
  padding: ${(props: GridOptionsOptional) => Spacings[props.padding]}rem;
`;
