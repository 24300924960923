// @flow
import styled from 'styled-components';
import type { StyledComponent } from 'styled-components';
import { Colors } from 'styles';

export const PWATitleBar: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  position: fixed;
  height: env(titlebar-area-height, 0);
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${Colors.gray2};
  app-region: drag;
`;
