// @flow
import styled from 'styled-components';
import type { StyledComponent } from 'styled-components';
import { MentionAvatar } from 'common/ui/MentionAvatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import { Colors, TextCss } from 'styles';
import type { MentionAvatarProps } from '../ui/MentionAvatar/MentionAvatar';
import type { SvgIconProps } from '@material-ui/core/@@SvgIcon';

export const SettingsCard: StyledComponent<{}, { ... }, HTMLDivElement> = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.gray3};
  text-align: initial;
  overflow: hidden;
  height: 100%;
`;

export const SettingsCardHeader: StyledComponent<{}, { ... }, HTMLDivElement> = styled.div`
  padding: 1rem 3rem;
`;

export const SettingsCardContent: StyledComponent<{}, { ... }, HTMLDivElement> = styled.div`
  padding: 1.5rem 3rem;
  overflow: auto;
  min-height: 0;
`;

export const SettingsLabel: StyledComponent<{}, { ... }, HTMLLabelElement> = styled.label`
  display: block;
  margin-left: 1.5rem;
  margin-bottom: 0.2rem;
  ${TextCss.caption}
`;

export const SettingsAvatar: StyledComponent<
  {},
  { ... },
  React$ComponentType<MentionAvatarProps>,
> = styled(MentionAvatar)`
  background-color: ${Colors.blue1};
  width: 6rem;
  height: 6rem;
  margin-top: 1rem;
  ${TextCss.display2}
`;

export const SettingsSeparator: StyledComponent<{}, { ... }, HTMLHRElement> = styled.hr`
  border: none;
  border-bottom: 1px solid ${Colors.gray6};
  margin: 2rem 0;
`;

export const SettingsSuccessIcon: StyledComponent<
  {},
  { ... },
  React$ComponentType<SvgIconProps>,
> = styled(Check)`
  color: ${Colors.green5};
`;

export const SettingsErrorIcon: StyledComponent<
  {},
  { ... },
  React$ComponentType<SvgIconProps>,
> = styled(Close)`
  color: ${Colors.red5};
`;

export const SettingsProgressSpinner: StyledComponent<
  {},
  { ... },
  React$ComponentType<SvgIconProps>,
> = styled(CircularProgress)`
  color: ${Colors.blue4};
`;
