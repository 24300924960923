// @flow
import styled from 'styled-components';
import type { StyledComponent } from 'styled-components';
import { Colors, Fonts, TextCss } from 'styles';
import { acronym } from 'utils/common';

const Container: StyledComponent<{ ... }, empty, HTMLDivElement> = styled.div`
  position: relative;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: ${Colors.gray1};
  font-family: ${Fonts.mainFont};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: ${Colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  ${TextCss.body2}
`;

const Avatar = styled.img`
  position: absolute;
  border-radius: 50%;
  top: 0;
  width: 3.2rem;
  height: 3.2rem;
`;

export type MentionAvatarProps = {
  className?: string,
  name?: ?string,
  avatar?: string,
  onClick?: () => void,
  ...
};

export const MentionAvatar: React$ComponentType<MentionAvatarProps> = ({
  className,
  name,
  avatar,
  onClick,
  ...rest
}) => {
  return (
    <Container {...rest} className={className} onClick={onClick}>
      {acronym(name)}
      {Boolean(avatar) && <Avatar src={avatar} />}
    </Container>
  );
};
