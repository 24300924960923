// @flow
import { useToasterDispatch } from 'common/ui/Toaster';
import { useEventsListener, NAMESPACES } from 'modules/EventsManager';
import type { CrossTabNotification } from 'modules/EventsManager';
import { getPageType } from 'utils/pageTypes';

export const useCrossTabNotifier = () => {
  const { enqueueToast, closeToast } = useToasterDispatch();

  useEventsListener(
    NAMESPACES.CROSS_TAB_NOTIFICATION,
    ({ source, payload }: { source: 'local' | 'remote', payload: CrossTabNotification }) => {
      const currentPageType = getPageType(window.location.pathname);

      switch (payload.type) {
        case 'showCrossTabNotification':
          // Only show toasts on pages with a type listed in the targetPageTypes list
          const shouldEnqueueToast = payload.payload.targetPageTypes.some(
            (targetPageType) => targetPageType === currentPageType
          );
          if (shouldEnqueueToast) {
            const { targetPageTypes, ...toastProps } = payload.payload;
            enqueueToast(payload.payload.message, toastProps);
          }
          break;
        case 'closeCrossTabNotification':
          closeToast(payload.payload.id);
          break;
        default:
          throw new Error(`Unsupported action type "${payload.type}".`);
      }
    }
  );
};
