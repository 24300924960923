// @flow

import styled, { css } from 'styled-components';
import type { StyledComponent, PropsWithTheme } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import type { ToastPosition } from './Toast';
import { unreachableCaseError } from 'types';

// This is the same as Material UI under the hood
const PADDING_FROM_VERTICAL_EDGE_OF_VIEWPORT = 'calc(env(titlebar-area-height, 0px) + 24px)';
const PADDING_FROM_HORIZONTAL_EDGE_OF_VIEWPORT = '24px';

export type ToastContainerProps = $ReadOnly<{
  children: React$Node,

  /**
   * Where to position the toast on the screen.
   */
  position: ToastPosition,

  /**
   * Run the animations for children on mount of the component.
   * @default true
   */
  initial?: boolean,
}>;

const StyledToastContainer: StyledComponent<{ position: ToastPosition }, { ... }, HTMLDivElement> =
  styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2rem;
    position: fixed;

    ${({ position }: PropsWithTheme<{ position: ToastPosition }, { ... }>) => {
      switch (position) {
        case 'top-left':
          return css`
            top: ${PADDING_FROM_VERTICAL_EDGE_OF_VIEWPORT};
            left: ${PADDING_FROM_HORIZONTAL_EDGE_OF_VIEWPORT};
          `;
        case 'top-center':
          return css`
            top: ${PADDING_FROM_VERTICAL_EDGE_OF_VIEWPORT};
            left: 50%;
            transform: translateX(-50%);
          `;
        case 'top-right':
          return css`
            top: ${PADDING_FROM_VERTICAL_EDGE_OF_VIEWPORT};
            right: ${PADDING_FROM_HORIZONTAL_EDGE_OF_VIEWPORT};
          `;
        case 'center-right':
          return css`
            top: 50%;
            right: ${PADDING_FROM_HORIZONTAL_EDGE_OF_VIEWPORT};
            transform: translateY(-50%);
          `;
        case 'bottom-right':
          return css`
            bottom: ${PADDING_FROM_VERTICAL_EDGE_OF_VIEWPORT};
            right: ${PADDING_FROM_HORIZONTAL_EDGE_OF_VIEWPORT};
          `;
        case 'bottom-center':
          return css`
            bottom: ${PADDING_FROM_VERTICAL_EDGE_OF_VIEWPORT};
            left: 50%;
            transform: translateX(-50%);
          `;
        case 'bottom-left':
          return css`
            bottom: ${PADDING_FROM_VERTICAL_EDGE_OF_VIEWPORT};
            left: ${PADDING_FROM_HORIZONTAL_EDGE_OF_VIEWPORT};
          `;
        case 'center-left':
          return css`
            top: 50%;
            left: ${PADDING_FROM_HORIZONTAL_EDGE_OF_VIEWPORT};
            transform: translateY(-50%);
          `;
        default:
          return unreachableCaseError(position);
      }
    }};
  `;

export const ToastContainer = ({
  children,
  position,
  initial = true,
}: ToastContainerProps): React$Node => {
  return (
    <StyledToastContainer
      position={position}
      data-testid="toast-container"
      data-position={position}
    >
      <AnimatePresence initial={initial}>{children}</AnimatePresence>
    </StyledToastContainer>
  );
};
